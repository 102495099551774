.slides {
  padding: 0%;
    position: relative;
    .slick-prev, .slick-next {
      position: absolute;
      top: 50%;
    }
    .slick-prev {
        left: 3%;
        z-index: 1;
        scale: 1.8;
    }
    .slick-next {
      right: 5%;
      scale: 1.8;
    }

    .slick-prev:before,
    .slick-next:before {
    color: black;
}
  }