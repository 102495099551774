
@font-face {
  font-family: "riode";
  src: url("../fonts/riode115b.eot?5gap68");
  src: url("../fonts/riode115b.eot?5gap68#iefix") format("embedded-opentype"),
    url("../fonts/riode115b.ttf?5gap68") format("truetype"),
    url("../fonts/riode115b.woff?5gap68") format("woff"),
    url("../fonts/riode115b.html?5gap68#riode") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'rioticons';
  src: url('../fonts/rioticons-Regular.eot');
  src: url('../fonts/rioticons-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/rioticons-Regular.woff2') format('woff2'),
      url('../fonts/rioticons-Regular.woff') format('woff'),
      url('../fonts/rioticons-Regular.ttf') format('truetype'),
      url('../fonts/rioticons-Regular.svg#rioticons-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
[class*=" d-icon-"],
[class^="d-icon-"] {
  display: inline-block;
  line-height: 1;
}
[class*=" d-icon-"]:before,
[class^="d-icon-"]:before {
  font-family: "riode" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.r-icon{
  font-family: "rioticons";
}

.r-icon-cart:before{
  content: "\0048";
  font-size: 22px;
}
.r-icon-fb:before{
  content: "\0178";
  font-size: 22px;
}
.r-icon-twiter:before{
  content: "\00A7";
  font-size: 22px;
  margin-left: 5px;
}
.r-icon-youtube:before{
  content: "\00AF";
  font-size: 22px;
  margin-left: 5px;
}
.r-icon-down-arrow:before{
  content: "\00B2";
  font-size: 22px;
}
.r-icon-insta:before{
  content: "\00A1";
  font-size: 22px;
  margin-left: 5px;
}
.r-icon-whatsapp:before{
  content: "\00A9";
  font-size: 22px;
  margin-left: 5px;
}
.r-icon-linkedIn:before{
  content: "\00A3";
  font-size: 22px;
  margin-left: 5px;
}
.r-icon-alert:before{
  content: "\0049";
  font-size: 22px;
}
.r-icon-user:before{
  content: "\003f";
  font-size: 22px;
}
.r-icon-previous:before{
  content: "\00B3";
  font-size: 22px;
}
.r-icon-next:before{
  content: "\00B4";
  font-size: 22px;
}
.r-icon-uparrow::after{
  content: "\00B1";
  font-size: 12px;
}