/* Add the following styles to your CSS to style the dropdown arrow */
.category-item {
    margin-bottom: 10px;
  }
  
 
  
  .dropdown-arrow {
    font-size: 18px;
    transform: rotate(90deg);
    transition: transform 0.2s;
  }
  
  .category-name.active .dropdown-arrow {
    transform: rotate(-90deg);
  }
  .subcategory-name-arrow {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  