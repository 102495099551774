.aboutHeader{
    justify-content: center;
    background-image: url('../images/about-us.jpg');
    background-repeat: no-repeat;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 250px;
    padding: 3em 1em;
    background-color: #C8C3BF;
    background-position: center;
    background-size: cover;
    text-align: center;
}