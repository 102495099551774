.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.gallery-item {
    margin: 10px;
}
.gallery-item img {
    width: 100px; /* Thumbnail image size */
    height: auto;
    cursor: pointer;
}
.main-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.main-image {
    width: 300px; /* Main image size */
    height: auto;
    cursor: pointer;
}
.thumbnail-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.arrow {
    cursor: pointer;
    opacity: 1;
}
.disabled {
    display: none;
}
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 9999; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}
.modal-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 800px;
    text-align: center;
}
.close {
    color: #fff;
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
}
.modal-button {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
}
.selected-thumbnail {
border: 2px solid #ff0000; /* Red border as an example */
border-radius: 5px; /* Optional: Rounded border corners */
}
@media screen and (max-width: 600px) {
.gallery-item {
    margin: 5px; /* Decrease margin for smaller screens */
}
.gallery-item img {
    width: 80px; /* Decrease thumbnail image size for smaller screens */
}
.main-image {
    width: 90%; /* Adjust main image width for smaller screens */
}
.modal-content {
    width: 90%; /* Adjust modal content width for smaller screens */
}
.modal-button {
    font-size: 14px; /* Decrease font size of modal buttons for smaller screens */
}
}
.carousel-container {
    margin-top: 20px; /* Adjust as needed */
  }
  
  .image-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Four columns */
    grid-gap: 10px; /* Adjust as needed */
  }
  
  .grid-item {
    cursor: pointer; /* Ensure cursor changes on hover */
  }
  
  .grid-item img {
    max-width: 50%;
    height: auto;
  }
  .image-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
  }
  
  .grid-item {
    cursor: pointer;
  }
  
  .grid-item img {
    max-width: 100%;
    height: auto;
  }
  
  .hidden {
    display: none;
  }
  
  .visible {
    display: block;
  }
  .navigation button {
    border: none;
    background-color: var(--rm-bg);
    color: #ffffff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 30px;

  }
  .navigation .r-icon-next::before{
    font-size: 10px;
    margin:-2px 0 0 0;
    display: block;
  }
  .navigation .r-icon-previous::before{
    font-size: 10px;
    margin:-2px 0 0 0;
    display: block;
  }
  .thumbnail {
    width: 60px;
    height: 60px;
    margin:0 5px;
    cursor: pointer;
  }
  
  .selected-thumbnail {
    width: 60px;
  height: 60px;
  margin:0 5px;
    cursor: pointer;
    border: 2px solid blue;
  }
  .disabled {
    opacity: 0.5; /* Example style for a disabled button */
    cursor: not-allowed; /* Example style for a disabled button */
  }
  .product-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
  }
.product-container {
    position: relative;
  }
  
  .product-image {
    cursor: pointer;
  }
  
  .product-navigation {
    position: absolute;
    top: 50%;
    cursor: pointer;
    color: #fff;
  }
  
  .product-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #fff;
  }
  #lens { 
    background-color: rgba(233, 233, 233, 0.4);
  }
  
  
  @media (max-width: 750px) {
    .thumbnail {
        width: 80px;
        height: 80px;
        margin: 5px;
        cursor: pointer;
      }
      
      .selected-thumbnail {
        width: 60px;
        height: 60px;
        margin: 5px;
        cursor: pointer;
        border: 2px solid blue;
      }
      #lens{
        background-color: rgba(233, 233, 233, 0);
      }
.product-image{
  width: 100%;
}
#lens { 
  background-color:none !important;
}
  }
  
  .slideshow-thumbnails {
    width: auto;
    height: 45px ;
  }
  
  #slideshow-items-container { 
    display: inline-block; 
    position: relative; 
  }
  #result{
    display: none;
  }

  #lens, #result { 
    position: absolute; 
    display: none; 
    z-index: 1; 
  }
  .slideshow-items { 
    display: none; 
  }
  .slideshow-items.active { 
    display: block; 
  }
  .slideshow-thumbnails { 
    opacity: 0.5; 
  }
  .slideshow-thumbnails.active { 
    opacity: 1; 
  }
  
  #lens, .slideshow-items, .slideshow-thumbnails, #result { 
    border: solid var(--light-grey-2) 1px; 
  }
  
.thumb button {
  border: none;
  background: none;
  }
  .thumb .r-icon-next::before, .r-icon-previous:before {
    font-size: 12px;
  }
  .thumb .active{
    border: var(--rm-bg) 1px solid;

  }
  .thumb img {
    margin: 0 5px;
  }
