.breadcrumb,
.category ul,
.comments ul,
.dropdown-box,
.filter-items,
.list,
.menu,
.menu ul,
.mobile-menu,
.mobile-menu ul,
.nav,
.nav-filters,
.nav ul,
.pagination,
.product-nav,
.product-tabs > div ul,
.select-menu > ul,
.widget-body,
.widget-body ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.sidebar-content li{
  list-style: none;
}
 
 
aside + div .product-single .product-details {
  padding-top: 2px;
}
 
 
 
@media (min-width: 992px) {
  .pg-vertical .product-thumbs-wrap {
    order: -1;
    max-width: 109px;
    margin: 0 1rem 0 0;
  }
  .pg-vertical .product-single-carousel {
    max-width: calc(100% - 119px);
  }
  .pg-vertical .product-thumbs {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .pg-vertical .product-thumb {
    margin: 0 0 1rem;
  }
  .pg-vertical .thumb-down,
  .pg-vertical .thumb-up {
    display: flex;
    width: 100%;
    height: 2.4rem;
  }
  .pg-vertical .thumb-up {
    transform: translateY(-100%);
  }
  .pg-vertical .thumb-up i:before {
    content: "";
  }
  .pg-vertical .thumb-down {
    top: auto;
    transform: translateY(100%);
  }
  .pg-vertical .thumb-down i:before {
    content: "";
  }
  .pg-vertical .product-label-group {
    left: 14rem;
  }
}
.product-single.product-sticky-both .p-sticky {
  top: 88px;
}
.product-single.product-sticky-both .product-details {
  padding: 0;
}
.product-tabs.tab-nav-simple .nav-link {
  font-size: 2rem;
  font-weight: 700;
  text-transform: capitalize;
  color: #222;
  letter-spacing: 0;
}
 
.right-sidebar-active .right-sidebar .sidebar-close,
.right-sidebar-active .right-sidebar .sidebar-overlay,
.sidebar-active .sidebar .sidebar-close,
.sidebar-active .sidebar .sidebar-overlay,
.top-sidebar-active .top-sidebar .sidebar-close,
.top-sidebar-active .top-sidebar .sidebar-overlay {
  visibility: visible;
  opacity: 1;
}
.right-sidebar-active .right-sidebar .sidebar-content,
.sidebar-active .sidebar .sidebar-content,
.top-sidebar-active .top-sidebar .sidebar-content {
  transform: translateX(0);
  opacity: 1;
}
.top-sidebar-active .category-sidebar {
  display: none;
}
.sidebar-active .page-wrapper {
  margin-left: 250px;
  margin-right: -250px;
}
.right-sidebar-active .page-wrapper {
  margin-left: -250px;
  margin-right: 250px;
}
.sidebar-content,
.sidebar-overlay,
.sidebar-toggle {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1200;
}
.sidebar-overlay {
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.4s, opacity 0.4s;
}
.sidebar-close {
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.4s, opacity 0.4s;
}
.sidebar-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 160px;
  width: 40px;
  height: 40px;
  font-size: 1.4rem;
  color: #fff;
  background-color: rgba(28, 26, 26, 0.8);
}
.sidebar-close {
  position: fixed;
  margin: 0;
  left: calc(100vw - 52px);
  top: 12px;
  font-size: 3.5rem;
  color: #ccc;
  z-index: 1201;
}
.sidebar-close:hover {
  color: #ccc;
}
.sidebar-content {
  bottom: 0;
  width: 30rem;
  padding: 2rem;
  transform: translateX(-100%);
  overflow: auto;
  background-color: #fff;
  opacity: 0;
  line-height: 1.3;
  transition: transform 0.4s, opacity 0.4s;
}
.sidebar-content .widget {
  border-top: 3px solid #eee;
}
.sidebar-content .widget-body {
  margin-bottom: 1.8rem;
  opacity: 1;
  transition: opacity 0.3s;
}
.sidebar-content .sidebar-close {
  display: flex;
  position: static;
  align-items: center;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: -0.025em;
  line-height: 1;
}
.sidebar-content .d-icon-times {
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 0.6rem;
}
.sidebar-content .widget.price-with-count .filter-items > li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar-content .btn-filter {
  padding: 0.86em 2em;
  border-radius: 2px;
}
.sidebar-content .btn-filter:active,
.sidebar-content .btn-filter:focus,
.sidebar-content .btn-filter:hover {
  background-color: #fe7c00;
  border-color: #fe7c00;
}
.right-sidebar {
  order: 2;
}
.right-sidebar .sidebar-toggle {
  left: auto;
  right: 0;
}
.right-sidebar .sidebar-close {
  left: 50px;
}
.right-sidebar .sidebar-content {
  transform: translateX(100%);
  left: auto;
  right: 0;
}
@media (max-width: 991px) {
  .right-sidebar .sidebar-close {
    left: 20px;
  }
}
@media (min-width: 992px) {
  .sidebar-fixed .sidebar-close,
  .sidebar-fixed .sidebar-overlay,
  .sidebar-fixed .sidebar-toggle {
    display: none;
  }
  .sidebar-fixed .sidebar-content {
    position: relative;
    overflow: visible;
    padding: 0;
    opacity: 1;
    z-index: 22;
  }
  .sidebar-fixed .sidebar-content {
    width: auto;
    transform: none;
  }
}
.right-sidebar .sidebar-content::-webkit-scrollbar {
  width: 0;
}
.tag {
  display: inline-block;
  padding: 0.6rem 1.3rem;
  margin: 0.5rem 1rem 0.5rem 0;
  border: 1px solid #ccc;
  font-size: 1.2rem;
  line-height: 1.35;
  letter-spacing: -0.01em;
  transition: color 0.3s, border-color 0.3s;
}
.tag:hover {
  color: #fe7c00;
  border-color: #fe7c00;
}
.sidebar-content .form-control {
  border-color: #ccc;
  border-radius: 0.3rem;
}
.widget-collapsible > .widget-title.collapsed {
  padding-bottom: 2.7rem;
}
.widget-collapsible
  > .widget-title.collapsed
  .widget-collapsible
  > .widget-title {
  padding-bottom: 0;
}
.widget-collapsible .toggle-btn {
  display: block;
  position: absolute;
  top: 36px;
  right: 12px;
  padding: 0;
  width: 10px;
  opacity: 1;
}
.widget-collapsible .toggle-btn:after,
.widget-collapsible .toggle-btn:before {
  content: "";
  position: absolute;
  border-top: 2px solid #666;
  width: 10px;
  transition: transform 0.3s;
}
.widget-collapsible > .collapsed .toggle-btn:before {
  transform: rotate(90deg);
}
.widget-collapsible > .collapsed .toggle-btn:after {
  transform: rotate(180deg);
}
.widget-collapsible .collapsed ~ .widget-body {
  opacity: 0.1;
}
.widget-collapsible p {
  margin-bottom: 0;
  color: #aaa;
  line-height: 1.86;
}
.toolbox-wrap .widget-title,
.widget-collapsible .widget-title {
  position: relative;
  cursor: pointer;
  margin: 0;
  padding: 2.6rem 0.3rem 1.8rem;
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: -0.03em;
  text-transform: none;
  border: none;
  transition: padding 0.3s;
}
.filter-items.search-ul li {
  padding: 13.5px 3px;
}
.filter-items li {
  padding: 13px 3px 13px 30px;
  font-size: 1.3rem;
  color: #222;
}
.filter-items > li:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.filter-items a {
  position: relative;
  display: block;
}
.filter-items a:hover {
  color: #fe7c00;
}
.filter-items .active > a:before {
  content: "";
  color: #fff;
  background-color: #222;
  border-color: #222;
}
.filter-items.search-ul a:before {
  content: none;
}
.filter-items .children li {
  padding: 5.5px 0;
}
.filter-items li span {
  margin-left: 0.5rem;
  color: #aaa;
}
.filter-items .with-ul > a > i {
  content: "";
  position: absolute;
  top: 50%;
  right: 0.7rem;
  line-height: 0;
  margin: -1.3rem -3px 0 0;
  padding: 1.2rem 5px;
  font-size: 1.2rem;
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  transition: transform 0.3s;
}
.filter-items .show > a {
  color: #fe7c00;
}
.filter-items .show > a i {
  transform: rotate(-180deg);
}
.filter-items ul {
  display: none;
  position: relative;
  margin: 1rem 0 -0.5rem;
  padding-left: 1.3rem;
}
.filter-items ul:before {
  content: "";
  position: absolute;
  left: 2px;
  top: 4px;
  bottom: 3px;
  border-left: 1px solid #eee;
}
.filter-items .color {
  top: 50%;
  display: inline-block;
  margin-right: 0.8rem;
  margin-top: -4.5px;
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
}
.widget-vendor-info li span {
  display: inline-block;
  margin-left: 0;
}
.widget-vendor-info li span:first-child {
  min-width: 10.5rem;
  padding-right: 1rem;
  color: #333;
}
.widget-vendor-info li span.ratings-container {
  margin-bottom: 0;
  padding-right: 0;
  font-size: 1.3rem;
}
.widget-vendor-info .seller-name .details {
  color: #222;
}
.widget-vendor-info .filter-items li {
  display: flex;
  padding: 14px 3px 13px 0px;
}
.widget-contact-vendor .form-control {
  min-height: 4.1rem;
  padding: 1.2rem 2rem;
  border: 1px solid #e1e1e1;
  line-height: 1.5;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 300;
  color: #999;
  transition: color 0.3s, border-color 0.3s;
}
.widget-contact-vendor .form-control:focus {
  border-color: #222;
}
.widget-contact-vendor .form-control:not(:last-child) {
  margin-bottom: 1rem;
}
.widget-contact-vendor .btn {
  margin: 0.8rem 0 1.2rem;
  padding: 0.8em 1.65em;
  letter-spacing: -0.025em;
}
.social-links {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
}
.social-links.square-link .social-link {
  border-radius: 0;
}
.social-links.rounded-link,
.social-links.square-link {
  display: flex;
  justify-content: center;
}
.social-links.rounded-link .social-link,
.social-links.square-link .social-link {
  margin: 5px 15px;
  border: none;
  font-size: 24px;
  width: 45px;
  height: 45px;
  line-height: 45px;
}
.social-links.rounded-link .social-link:not(:hover),
.social-links.square-link .social-link:not(:hover) {
  color: #fff;
  background-color: #e1e1e1;
}
.social-links.rounded-link .social-link {
  border-radius: 0.3rem;
}
.social-link-active {
  display: flex;
  justify-content: center;
}
.social-link-active .social-link {
  border: none;
  margin: 1rem;
  font-size: 26px;
  line-height: 60px;
  width: 60px;
  height: 60px;
  color: #fff;
}
.social-link-active .social-facebook {
  background: #3b5998;
  border-color: #3b5998;
}
.social-link-active .social-twitter {
  background: #1da1f2;
  border-color: #1da1f2;
}
.social-link-active .social-linkedin {
  background: #0073b2;
  border-color: #0073b2;
}
.social-link-active .social-email {
  background: #dd4b39;
  border-color: #dd4b39;
}
.social-link-active .social-google {
  background: #dd4b39;
  border-color: #dd4b39;
}
.social-link-active .social-pinterest {
  background: #bd081c;
  border-color: #bd081c;
}
.social-link-active .social-reddit {
  background: #ff4107;
  border-color: #ff4107;
}
.social-link-active .social-tumblr {
  background: #304e6c;
  border-color: #304e6c;
}
.social-link-active .social-vk {
  background: #6383a8;
  border-color: #6383a8;
}
.social-link-active .social-youtube {
  background: #fe7c00;
  border-color: #fe7c00;
}
.social-link-active .social-whatsapp {
  background: #3c8a38;
  border-color: #3c8a38;
}
.social-link-active .social-xing {
  background: #1a7576;
  border-color: #1a7576;
}
.social-link-active .social-instagram {
  background: #7c4a3a;
  border-color: #7c4a3a;
}
.social-link {
  margin: 2px 8px 2px 0;
  width: 30px;
  height: 30px;
  line-height: 28px;
  font-size: 1.5rem;
  border-radius: 50%;
  border: 2px solid #ccc;
  color: inherit;
  transition: color 0.4s, border 0.4s, background 0.4s;
  text-align: center;
}
.social-link:last-child {
  margin-right: 0;
}
.social-link i {
  letter-spacing: -0.001em;
  line-height: 1;
}
.social-link:hover {
  background: #fe7c00;
  border-color: #fe7c00;
  color: #fff;
}
.social-link.social-facebook:hover {
  background: #3b5998;
  border-color: #3b5998;
}
.social-link.social-twitter:hover {
  background: #1da1f2;
  border-color: #1da1f2;
}
.social-link.social-linkedin:hover {
  background: #0073b2;
  border-color: #0073b2;
}
.social-link.social-email:hover {
  background: #dd4b39;
  border-color: #dd4b39;
}
.social-link.social-google:hover {
  background: #dd4b39;
  border-color: #dd4b39;
}
.social-link.social-pinterest:hover {
  background: #bd081c;
  border-color: #bd081c;
}
.social-link.social-reddit:hover {
  background: #ff4107;
  border-color: #ff4107;
}
.social-link.social-tumblr:hover {
  background: #304e6c;
  border-color: #304e6c;
}
.social-link.social-vk:hover {
  background: #6383a8;
  border-color: #6383a8;
}
.social-link.social-youtube:hover {
  background: #fe7c00;
  border-color: #fe7c00;
}
.social-link.social-whatsapp:hover {
  background: #3c8a38;
  border-color: #3c8a38;
}
.social-link.social-xing:hover {
  background: #1a7576;
  border-color: #1a7576;
}
.social-link.social-instagram:hover {
  background: #7c4a3a;
  border-color: #7c4a3a;
}
.no-border .social-link {
  border: 0;
  line-height: 30px;
}
.no-border .social-link:not(:hover) {
  background: transparent;
}
.no-backgrond .social-link {
  background-color: none;
  line-height: 30px;
}
.no-backgrond .social-link:not(:hover) {
  background: transparent;
}
.inline-links .social-link {
  display: inline-block;
  margin-right: 2rem;
  width: auto;
  height: auto;
  border: 0;
  color: inherit;
}
.inline-links .social-link:not(:last-child) {
  margin-right: 2rem;
}
.inline-links .social-link:hover {
  background: transparent;
}
.inline-links .social-link.social-facebook:hover {
  color: #3b5998;
}
.inline-links .social-link.social-twitter:hover {
  color: #1da1f2;
}
.inline-links .social-link.social-linkedin:hover {
  color: #0073b2;
}
.inline-links .social-link.social-email:hover {
  color: #dd4b39;
}
.inline-links .social-link.social-google:hover {
  color: #dd4b39;
}
.inline-links .social-link.social-pinterest:hover {
  color: #bd081c;
}
.inline-links .social-link.social-reddit:hover {
  color: #ff4107;
}
.inline-links .social-link.social-tumblr:hover {
  color: #304e6c;
}
.inline-links .social-link.social-vk:hover {
  color: #6383a8;
}
.inline-links .social-link.social-whatsapp:hover {
  color: #3c8a38;
}
.inline-links .social-link.social-xing:hover {
  color: #1a7576;
}
.inline-links .social-link.social-instagram:hover {
  color: #7c4a3a;
}
.social-link-template .inline-links .social-link {
  font-size: 24px;
  margin: 3px 19px;
}
.social-link-template .inline-links .social-link:not(:hover) {
  color: #999;
}
.social-default .social-link {
  width: 45px;
  height: 45px;
  margin: 3px 7.5px;
  line-height: 45px;
  font-size: 24px;
}
.social-default .social-link:not(:hover) {
  color: #999;
}
.tab-content {
  background-color: transparent;
}
.nav-tabs {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #ebebeb;
}
.nav-tabs .nav-item .nav-link.active,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-item:hover .nav-link {
  color: #222;
}
.nav-link {
  display: block;
  margin-bottom: -0.1rem;
  padding: 1.6rem 0.2rem;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0;
  text-align: center;
  border-radius: 0;
  border: 0;
  transition: border 0.35s, color 0.35s, background-color 0.35s;
}
.nav-link-with-img {
  position: relative;
  margin-bottom: -4px;
  height: 100%;
  padding: 1.5rem 0 2rem;
}
.nav-link-with-img figure {
  height: 7.4rem;
  width: 12.1rem;
}
.nav-link-with-img .img-cat-title {
  position: absolute;
  bottom: 1.5rem;
  font-size: 1.4rem;
  font-weight: 500;
  min-width: 12.1rem;
}
.nav-link-with-img:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom: 4px solid #fe7c00;
  transform: scaleX(0) translateX(-50%);
  transform-origin: left;
  transition: transform 0.3s;
}
.nav-link-with-img.active:after,
.nav-link-with-img:hover:after {
  transform: scaleX(1) translateX(-50%);
}
.nav-item:not(:last-child) {
  margin-right: 2.5rem;
}
.nav-fill .nav-item {
  flex: 1;
}
.tab-content {
  position: relative;
}
.tab-content > .tab-pane {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 0 !important;
  opacity: 0;
  z-index: -1;
  transition: visibility 0.3s, opacity 0.3s;
}
.tab-content > .tab-pane:not(.active) {
  overflow: hidden;
  visibility: hidden;
}
.tab-content > .active {
  position: relative;
  height: auto !important;
  opacity: 1;
  z-index: auto;
}
.tab-pane {
  padding: 2.3rem 0;
  line-height: 1.72;
  color: #666;
}
.tab-pane p {
  line-height: 1.72;
}
.tab-nav-simple .nav-link {
  border-bottom: 2px solid transparent;
  background-color: transparent;
  color: #666;
}
.tab-nav-simple .nav-item .nav-link.active,
.tab-nav-simple .nav-item.show .nav-link,
.tab-nav-simple .nav-item:hover .nav-link {
  border-bottom-color: #fe7c00;
}
.tab-nav-center .nav {
  justify-content: center;
}
.tab-nav-right .nav {
  justify-content: flex-end;
}
.tab-nav-boxed .nav-item {
  margin-right: 0;
}
.tab-nav-boxed .nav-link {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}
.tab-nav-round .nav-item:not(:last-child) {
  margin-right: 2px;
}
.tab-nav-round .nav-link {
  margin-bottom: 0;
  border-radius: 2.3rem;
  background-color: #f2f3f5;
}
.tab-nav-round .nav-tabs {
  border-bottom: 0;
}
.tab-boxed .tab-pane {
  padding-left: 2rem;
  padding-right: 2rem;
}
.tab-outline .tab-content {
  border: 1px solid #ebebeb;
}
.tab-outline .nav-tabs {
  border-bottom: 0;
}
.tab-outline .nav-link {
  padding-top: 1.4rem;
  padding-bottom: 1.5rem;
  border: 1px solid transparent;
  border-top-width: 2px;
}
.tab-outline .nav-item .nav-link.active,
.tab-outline .nav-item.show .nav-link,
.tab-outline .nav-item:hover .nav-link {
  border-color: #ebebeb;
  border-top-color: #fe7c00;
}
.tab-outline2 .nav {
  position: relative;
  z-index: 1;
}
.tab-outline2 .tab-content {
  border: 1px solid #ebebeb;
}
.tab-outline2 .nav-tabs {
  border-bottom: 0;
}
.tab-outline2 .nav-link {
  padding: 1.5rem 2.4rem;
  border: 1px solid transparent;
}
.tab-outline2 .nav-item .nav-link.active,
.tab-outline2 .nav-item.show .nav-link,
.tab-outline2 .nav-item:hover .nav-link {
  border-color: #ebebeb;
  border-bottom-color: #fff;
}
.tab-vertical {
  display: flex;
}
.tab-vertical .nav-tabs {
  flex-flow: column nowrap;
  width: 27.8%;
  border: 0;
  border-right: 2px solid #ebebeb;
}
.tab-vertical .tab-content {
  flex: 1;
}
.tab-vertical .nav-item {
  margin-right: 0;
}
.tab-vertical .nav-link {
  position: relative;
  margin-right: -2px;
  padding-left: 2rem;
  width: calc(100% + 2px);
  border: 0;
  text-align: left;
}
.tab-vertical .nav-link:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  width: 2px;
  height: calc(100% - 6px);
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  z-index: 1;
}
.tab-vertical .tab-pane {
  padding: 1.7rem 2rem;
}
.tab-vertical.tab-simple .nav-link {
  margin-right: -2px;
}
.tab-vertical.tab-simple .nav-link:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  width: 2px;
  height: calc(100% - 6px);
  top: 50%;
  transform: translateY(-50%) scale(0);
  background-color: transparent;
  transition: transform 0.3s;
  z-index: 1;
}
.tab-vertical.tab-simple .nav-item .nav-link.active,
.tab-vertical.tab-simple .nav-item.show .nav-link,
.tab-vertical.tab-simple .nav-item:hover .nav-link {
  color: #fe7c00;
}
.tab-vertical.tab-simple .nav-item .nav-link.active:after,
.tab-vertical.tab-simple .nav-item.show .nav-link:after,
.tab-vertical.tab-simple .nav-item:hover .nav-link:after {
  background-color: #fe7c00;
  transform: translateY(-50%) scale(1);
}
@media (max-width: 767px) {
  .tab-vertical {
    flex-flow: column nowrap;
  }
  .tab-vertical .nav-tabs,
  .tab-vertical .tab-content {
    width: 100%;
  }
  .tab-vertical .nav-tabs {
    border: 0;
  }
  .tab-vertical .tab-content {
    padding-left: 0;
  }
  .tab-vertical.tab-simple .nav-link:after {
    height: 2px;
    width: 100%;
    background-color: transparent;
    top: calc(100% - 2px);
    transform: scale(0);
  }
  .tab-vertical.tab-simple .nav-item .nav-link.active:after,
  .tab-vertical.tab-simple .nav-item.show .nav-link:after,
  .tab-vertical.tab-simple .nav-item:hover .nav-link:after {
    transform: scale(1);
  }
}
.tab-inverse .tab-content {
  border-color: #fff;
}
.tab-inverse .nav-tabs {
  border: 0;
}
.tab-inverse .nav-link {
  margin: 0;
  width: 100%;
}
.tab-inverse .nav-item .nav-link.active,
.tab-inverse .nav-item.show .nav-link,
.tab-inverse .nav-item:hover .nav-link {
  background-color: #fff;
  border-color: #fff;
}
.tab-inverse.tab-simple .nav-link {
  padding-top: 1.4rem;
  border-top: 2px solid transparent;
}
.tab-inverse.tab-simple .nav-item .nav-link.active,
.tab-inverse.tab-simple .nav-item.show .nav-link,
.tab-inverse.tab-simple .nav-item:hover .nav-link {
  border-top-color: #fe7c00;
}
.tab-nav-solid .nav-link {
  padding-bottom: 1.6rem;
  border: 0;
}
.tab-nav-solid .nav-item .nav-link.active,
.tab-nav-solid .nav-item.show .nav-link,
.tab-nav-solid .nav-item:hover .nav-link {
  color: #fff;
  background-color: #fe7c00;
  border-color: #fe7c00;
}
@media (max-width: 767px) {
  .nav-link {
    font-size: 1.4rem;
  }
  .tab-nav-boxed .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (max-width: 479px) {
  .tab-nav-round .nav-link {
    margin-bottom: 0.5rem;
  }
}
.nav-filters {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: #666;
}
.nav-filters li:not(:last-child) {
  margin-right: 1.8rem;
}
.nav-filter {
  display: inline-block;
  position: relative;
  padding: 0.5rem 0;
  font-size: 1.4rem;
  letter-spacing: -0.01em;
  line-height: 1;
  transition: color 0.3s;
}
.nav-filter.active {
  color: #fe7c00;
}
.filter-underline .nav-filter {
  border-bottom: 1px solid transparent;
  transition: color 0.3s, border-color 0.3s;
}
.filter-underline .nav-filter.active {
  border-color: #fe7c00;
}
.tooltiptext {
  font-family: poppins;
  position: absolute;
  padding: 1rem 0.7rem;
  bottom: 150%;
  left: 48%;
  background-color: #333;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: 0.01em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  transform: translateX(-50%);
  z-index: 1;
  transition: opacity 0.3s;
}
.tooltip-bottom:after,
.tooltip-top:after {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
}
.tooltip-top:after {
  top: 96%;
  border-color: #333 transparent transparent transparent;
}
.tooltip-bottom:after {
  top: -37%;
  border-color: transparent transparent #333 transparent;
}
 
.title-link a {
  color: #444;
  font-size: 1.2rem;
}
.title-link a:hover {
  color: #fe7c00;
}
.title-link i {
  font-size: 1rem;
}
.with-link {
  display: flex;
  justify-content: space-between;
  line-height: 1;
}
.with-link:after {
  background-color: #e1e1e1;
}
.with-link a {
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  font-weight: 700;
  font-size: 13px;
  line-height: 2.05em;
  text-transform: uppercase;
  letter-spacing: -0.325px;
  color: #222;
  transition: color 0.3s;
}
.with-link a:hover {
  color: #fe7c00;
}
.with-link i {
  margin-left: 0.9rem;
  font-size: 1.9rem;
  line-height: 0;
}
.title-echo {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 7px;
  color: #f3f3f3;
  font-size: 8.89rem;
  letter-spacing: -0.2px;
}
.title-echo span {
  position: absolute;
  font-size: 3.74rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #222;
}
@media (max-width: 767px) {
  .title-echo {
    font-size: 3.2em;
  }
}
.vendor-details {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.vendor-logo {
  margin-right: 1rem;
}
.vendor-name {
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: -0.025em;
}
.vendor-products-count {
  font-weight: 400;
  font-size: 1.2rem;
  color: #666;
}
.vendor-products.grid-type {
  display: grid;
  grid-template-columns: repeat(auto-fill, calc(100% / 3));
  grid-auto-rows: 1fr;
}
.vendor-products.grid-type .vendor-product {
  grid-column-end: span 1;
  grid-row-end: span 1;
}
.market .store {
  position: relative;
  box-shadow: 0px 0px 25px 0px #ddd;
  border-radius: 0.3rem;
  overflow: hidden;
  margin-bottom: 2rem;
}
.market .store .store-content {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  padding: 2rem 2.5rem;
  background: rgba(0, 0, 0, 0.45);
  color: #fff;
}
.market .store .store-title {
  display: flex;
  align-items: center;
}
.market .store .store-title a {
  display: inline-block;
  margin-bottom: 0;
  color: #fff;
  text-shadow: rgba(0, 0, 0, 0.8) 0 1px 0;
  font-size: 25px;
}
.market .store .featured-label {
  width: max-content;
  width: -moz-max-content;
  width: -webkit-max-content;
  display: inline-block;
  margin-left: 1rem;
  padding: 0.4rem 1rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  background: #2d54a3;
  color: #fff;
  border-radius: 3px;
  box-shadow: 0px 0px 25px -5px #afafaf;
}
.market .store .ratings-container {
  margin: 0 0 1.1rem 0.2rem;
  font-size: 1.4rem;
  letter-spacing: 0.3em;
}
.market .store .ratings-full:before {
  content: "" "" "" "" "";
  color: #666666;
}
.market .store p {
  margin-bottom: 0.2rem;
  line-height: 1.65;
}
.market .store .store-phone {
  font-size: 1.3rem;
}
.market .store .store-phone i {
  margin: 0 0.3rem 0.4rem 0;
  font-size: 1.1rem;
  transform: rotateZ(93deg);
  vertical-align: middle;
}
.market .store .store-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2.5rem 2rem 2.4rem;
  background-color: #fff;
  z-index: 90;
}
.market .store .seller-avatar {
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem;
  position: absolute;
  background-color: #fff;
  right: 2.5rem;
  bottom: 6rem;
  overflow: hidden;
  box-shadow: 0px 0px 30px -6px #afafaf;
}
.market .store .seller-avatar img {
  border-radius: 50%;
}
.market .store .btn-link {
  text-transform: capitalize;
}
.market .store .btn-link i {
  font-size: 2rem;
}
.store-list {
  display: flex;
  align-items: center;
}
.store-list .store-header {
  position: relative;
}
.store-list .store-header .featured-label {
  position: absolute;
  top: 1rem;
  left: 1.5rem;
  margin-left: 0;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
.store-list .store-content {
  position: relative;
  background-color: #fff;
}
.store-list .store-title a {
  color: #222;
}
.store-list .store-footer {
  position: relative;
}
.vendor-type2 .vendor-details .ratings-container {
  font-size: 1.2rem;
}
.vendor-type2 .vendor-products.grid-type.gutter-xs {
  margin: -2.5px;
}
.vendor-type2 .vendor-products.grid-type.gutter-xs > * {
  padding: 2.5px;
}
.vendor-type2 .vendor-products.grid-type .vendor-product:first-child {
  grid-column-end: span 2;
  grid-row-end: span 2;
}
.vendor-type2 .vendor-products.grid-type .product-media {
  border-radius: 0.3rem;
  overflow: hidden;
  height: 100%;
}
.vendor-type2 .vendor-products.grid-type .product-media img {
  height: 100%;
}
.vendor-type3 .vendor-details {
  flex-direction: column;
  margin-bottom: 10px;
}
.vendor-type3 .vendor-name {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vendor-type3 .vendor-name a {
  margin-bottom: 5px;
}
.vendor-type3 .vendor-logo {
  position: relative;
  margin-right: 0;
  transform: translateY(-50%);
}
.vendor-type3 .vendor-logo img {
  position: relative;
  border-radius: 100%;
  transition: transform 0.35s;
}
.vendor-type3 .vendor-logo:before {
  content: "";
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  z-index: -1;
  background-color: #fe7c00;
}
.vendor-type3.vendor-widget:hover .vendor-logo img {
  transform: translateY(-10px);
}
.vendor-type3 .vendor-personal {
  margin-top: -25px;
}
.widget-title {
  margin-bottom: 2rem;
  padding: 1.1rem 0;
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.2;
  border-bottom: 1px solid #e1e1e1;
  letter-spacing: -0.025em;
}
.widget-products .widget-title {
  padding: 2.8rem 0.3rem 1.6rem;
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: -0.03em;
}
 
.mobile-search {
  display: none;
}
.mobile-search .search-toggle i {
  font-size: 2rem;
  vertical-align: middle;
}
.hs-toggle.dir-up:after {
  top: auto;
  bottom: 100%;
  border-bottom-color: transparent;
  border-top-color: #fff;
}
.hs-toggle.dir-up .input-wrapper {
  top: auto;
  bottom: 100%;
  transform: translate3d(0, 10px, 0);
}

.search-toggle {
  display: inline-block;
  vertical-align: middle;
  padding: 1.1rem 0;
  transition: color 0.3s;
}
.search-toggle span {
  cursor: pointer;
}
.search-toggle i {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 0.9;
}
.search-toggle.with-label {
  display: inline-flex;
  align-items: center;
}
.search-toggle.with-label i {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2px;
  font-size: 1.6rem;
}
.search-toggle.with-label span {
  margin-left: 0.5rem;
  cursor: pointer;
  font-weight: inherit;
  letter-spacing: inherit;
  text-transform: uppercase;
}
.main-nav {
  margin: 0;
}
.call {
  display: flex;
  align-items: center;
}
.call span {
  font-weight: inherit;
  text-transform: uppercase;
  cursor: pointer;
}
.call i + span {
  margin-left: 0.9rem;
}
.call i {
  margin: 0 0.2rem 0.2rem 0;
  font-size: 1.8rem;
  cursor: pointer;
}
.call:hover {
  color: #fe7c00;
}
.wishlist {
  margin-right: 1.5rem;
}
.wishlist i {
  font-size: 2.2rem;
  cursor: pointer;
}
.wishlist:hover {
  color: #fe7c00;
}
@keyframes fixedTop {
  0% {
    margin-top: -60px;
  }
  to {
    margin-top: 0;
  }
}
@keyframes fixedBottom {
  0% {
    transform: translateY(100%);
    transform-origin: center top 0px;
  }
  to {
    transform: translateY(0);
  }
}
.sticky-header.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.4rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1100;
  animation: fixedTop 0.4s;
}
@media (max-width: 991px) {
  .sticky-wrapper {
    height: auto !important;
  }
}
.mobile-menu-toggle {
  display: none;
  margin-right: 2rem;
  font-size: 2.5rem;
  /* color: #fff; */
}
.mobile-menu-toggle.menu-bar:after,
.mobile-menu-toggle.menu-bar:before {
  content: "";
  display: block;
  width: 100%;
}
.mobile-menu-toggle.menu-bar:before {
  height: 11px;
  border-top: 2px solid;
  border-bottom: 2px solid;
}
.mobile-menu-toggle.menu-bar:after {
  height: 9px;
  border-bottom: 2px solid;
}
@media (max-width: 1199px) {
  .main-nav .menu > li {
    margin-right: 1.5rem;
  }
  .header-middle .logo {
    margin-right: 2rem;
  }
}
@media (max-width: 991px) {
  .header .main-nav {
    display: none;
  }
  .header-top .header-left {
    margin-right: 2rem;
  }
  .header-middle .header-right {
    justify-content: flex-end;
  }
  .header-middle .header-right .divider {
    display: none;
  }
  .header-middle .header-center {
    margin-left: auto;
    margin-right: auto;
  }
  .header-middle .compare,
  .header-middle .icon-box {
    margin-right: 1.5rem;
  }
  .mobile-menu-toggle {
    display: block;
    margin: 0 10px 0 10px;
  }
}
 
@keyframes show_msg_first {
  0% {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes show_msg {
  0% {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
.label-down {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.label-down i {
  display: inline-block;
}
.label-down span {
  display: block;
}
.label-down i + span {
  margin-top: 0.7rem;
}
.label-block span {
  display: block;
}
.label-block .cart-name {
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  font-weight: 400;
  text-transform: capitalize;
}
.label-block .cart-name :after {
  content: none;
}
.label-block .cart-price {
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: -0.05em;
}
.label-block.cart-toggle i {
  font-size: 3rem;
}
.mobile-link {
  display: block;
  margin-right: 0;
  margin-bottom: -3px;
}
.mobile-link i {
  font-size: 2.4rem;
}
.mobile-link + .mobile-link {
  margin-left: 2rem;
}
.mobile-link:hover {
  color: #fe7c00;
}
@media (min-width: 768px) {
  .mobile-link {
    display: none;
  }
}
 
.sticky-header:not(.fixed) .category-dropdown.menu-fixed:after {
  top: 100%;
  transform: translate3d(-50%, 0, 0);
}
.sticky-header:not(.fixed) .category-dropdown.menu-fixed .dropdown-box {
  top: calc(100% + 20px);
  transform: none;
}
.sticky-header:not(.fixed) .category-dropdown.menu-fixed .dropdown-box,
.sticky-header:not(.fixed) .category-dropdown.menu-fixed:after {
  visibility: visible;
  opacity: 1;
}
.sticky-header:not(.fixed) .category-dropdown.menu-fixed.has-border:before {
  top: calc(100% - 1px);
  visibility: visible;
  opacity: 1;
}
@media (-webkit-min-device-pixel-ratio: 1.5),
  (min--moz-device-pixel-ratio: 1.5),
  (min-device-pixel-ratio: 1.5) {
  .sticky-header:not(.fixed) .category-dropdown.menu-fixed.has-border:before {
    top: calc(100% - 2px);
  }
}
.menu {
  display: flex;
  align-items: center;
}
.menu a {
  display: inline-block;
}
.menu .menu-title {
  margin-bottom: 1rem;
  padding: 0 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
}
.menu ul {
  padding: 2rem 0;
  background: #fff;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0;
  color: #666;
}
.menu li {
  position: relative;
  line-height: 1.5;
}
.menu li a {
  padding: 0.7rem 0 0.7rem 1rem;
}
.menu li .megamenu,
.menu li > ul {
  position: absolute;
  top: -9999px;
  left: 100%;
  margin: 0;
  box-shadow: 0 2px 35px rgba(0, 0, 0, 0.1);
  z-index: 1041;
  visibility: hidden;
  opacity: 0;
  transition: transform 0.2s ease-out;
  transform: translate3d(0, -10px, 0);
}
.menu li > ul {
  min-width: 22.6rem;
  background: #fff;
}
.menu li > ul li {
  padding: 0 2rem;
}
.menu > li.submenu-container .megamenu {
  width: 1180px;
}
.menu .active > a:not(.menu-title),
.menu li:hover > a:not(.menu-title) {
  color: #fe7c00;
}
.menu .show .megamenu,
.menu .show > ul,
.menu li:hover .megamenu,
.menu li:hover > ul {
  visibility: visible;
  opacity: 1;
  top: -2rem;
  transform: translate3d(0, 0, 0);
}
.menu > li {
  margin-right: 3rem;
}
.menu > li:last-child {
  margin-right: 0;
}
.menu > li > a {
  position: relative;
  padding: 1.3rem 0;
  font-weight: 500;
  letter-spacing: inherit;
  line-height: 1;
  text-transform: capitalize;
}
.menu > li > a:after {
  margin-left: 0.8rem;
}
.menu > li .megamenu,
.menu > li > ul {
  left: -1.9rem;
}
.menu > li.show .megamenu,
.menu > li.show > ul,
.menu > li:hover .megamenu,
.menu > li:hover > ul {
  top: 100%;
}
.menu .submenu > a {
  position: relative;
  display: block;
}
.menu > .submenu > a {
  z-index: 10;
}
.menu > .submenu > a:after {
  position: static;
  content: "";
  margin-top: 0;
}
.menu.no-arrow > li > a:after {
  content: none;
}
.submenu > a:after {
  display: inline-block;
  position: absolute;
  margin-top: 2px;
  right: 0.5rem;
  top: 50%;
  line-height: 0;
  vertical-align: middle;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  font-size: 1.1rem;
  color: inherit;
  content: "";
}
.vertical-menu {
  display: block;
  min-width: 28rem;
}
.vertical-menu > li {
  margin-right: 0;
  padding: 0 2rem;
}
.vertical-menu > li .megamenu,
.vertical-menu > li > ul {
  transform: translate3d(-15px, 0, 0);
}
.vertical-menu > li > a {
  display: inline-block;
}
.vertical-menu > li > a:after {
  content: none;
}
.vertical-menu > li.show .megamenu,
.vertical-menu > li.show > ul,
.vertical-menu > li:hover .megamenu,
.vertical-menu > li:hover > ul {
  left: 100%;
  top: -1rem;
}
.vertical-menu > .submenu:after {
  border: 11px solid transparent;
  border-right: 11px solid #fff;
}
.vertical-menu > .submenu.show:after,
.vertical-menu > .submenu:hover:after {
  top: 50%;
  left: calc(100% - 20px);
  transform: translate3d(0, -50%, 0);
}
.vertical-menu > .submenu > a:after {
  position: absolute;
  line-height: 0;
  content: "";
}
.vertical-menu.no-arrow > li {
  display: flex;
}
.vertical-menu.no-arrow > li > a:after {
  content: none;
}
.toggle-menu {
  display: block;
}
.toggle-menu > li {
  margin-right: 0;
}
.toggle-menu .submenu:after,
.toggle-menu .submenu > a:after {
  content: none;
}
.toggle-menu .submenu li {
  padding: 0 0.5rem;
}
.toggle-menu li > ul {
  display: none;
  position: static;
  visibility: visible;
  opacity: 1;
  transform: none;
  background: transparent;
  box-shadow: none;
}
.toggle-btn {
  display: block;
  position: absolute;
  padding: 6px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.6;
}
.toggle-btn:before {
  content: "";
  display: block;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
  line-height: 1;
  transition: transform 0.5s;
}
.show > a .toggle-btn:before {
  transform: rotate(630deg);
}
.menu-active-underline > li > a:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 9px;
  left: 0;
  width: 100%;
  border-bottom: 2px solid;
  transform-origin: right center;
  transform: scale(0, 1);
  transition: transform 0.3s;
}
.menu-active-underline > li.active > a,
.menu-active-underline > li:hover > a {
  color: inherit;
}
.menu-active-underline > li.active > a:before,
.menu-active-underline > li:hover > a:before {
  transform-origin: left center;
  transform: scale(1, 1);
}
.main-nav {
  margin: 0;
}
.megamenu {
  display: flex;
  padding: 1rem;
  min-width: 94rem;
  background: #fff;
}
.megamenu ul {
  padding: 0;
}
.megamenu .row {
  flex: 1;
  padding: 0 1rem;
}
.megamenu .row > * {
  padding: 1.8rem 1rem 0.8rem;
}
.megamenu .menu-banner {
  padding: 0;
  overflow: hidden;
}
.megamenu .menu-banner figure {
  height: 100%;
}
.megamenu .menu-banner img {
  height: 100%;
  object-fit: cover;
}
.megamenu .menu-banner .btn-link:hover {
  color: #fe7c00;
}
.menu-banner1 .banner-content {
  left: 9%;
}
.menu-banner1 .banner-subtitle,
.menu-banner1 .banner-title {
  font-size: 3.6rem;
}
.menu-banner1 .banner-subtitle {
  margin-bottom: 0.4rem;
}
.menu-banner1 .banner-title {
  margin-bottom: 1.8rem;
  padding-left: 1.2rem;
  position: relative;
}
.menu-banner1 .banner-title span {
  display: inline-block;
  position: absolute;
  left: -0.9rem;
  top: 50%;
  font-size: 1.2rem;
  line-height: 1;
  transform: rotateZ(-90deg) translateX(0.6rem);
  letter-spacing: -0.1em;
}
.menu-banner2 .banner-content {
  bottom: 10%;
}
.menu-banner2 .banner-title {
  margin-bottom: 0.6rem;
  font-size: 2.6rem;
}
.menu-banner2 .banner-subtitle {
  font-size: 1.6rem;
}
.tip {
  display: inline-block;
  position: relative;
  top: -1px;
  left: 7px;
  padding: 0.3rem 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-transform: uppercase;
  border-radius: 2px;
}
.tip.tip-hot {
  background-color: #666666;
}
.tip.tip-new {
  background-color: #fe7c00;
}
.mobile-menu-wrapper {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  font-family: poppins;
  transition: visibility 0.4s;
}
.mobile-menu-container {
  max-width: 296px;
  padding: 2rem 1.5rem;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: #222529;
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.5);
  transform: translateX(-296px);
  transition: transform 0.4s;
}
.mobile-menu-container .input-wrapper {
  display: flex;
  margin-bottom: 0.6rem;
  height: 4rem;
}
.mobile-menu-container .input-wrapper .form-control {
  min-height: 4rem;
  color: #7a8088;
  border: 1px solid;
  border-color: #2e3237;
  border-right: 0;
  background-color: transparent;
}
.mobile-menu-container .input-wrapper .btn-search {
  padding: 0;
  width: 4rem;
  background-color: #fe7c00;
  border-color: #fe7c00;
  color: #fff;
  font-size: 1.4rem;
  border-radius: 3px;
}
.mobile-menu-container .input-wrapper .btn-search i {
  margin: 0 0 0.5rem;
  font-size: inherit;
}
.mobile-menu-container .mobile-menu {
  margin-bottom: 0.5rem;
  background: #222529;
}
.mobile-menu-container .tab {
  margin-top: 3rem;
}
.mobile-menu-container .tab-content {
  background-color: transparent;
}
.mobile-menu-container .nav {
  border-width: 2px;
}
.mobile-menu-container .nav-link {
  margin-bottom: -2px;
  color: #fff;
  font-size: 13px;
  padding: 10px;
}
.mobile-menu-container .tab-pane {
  padding-top: 10px;
}
.mobile-menu-container .nav-item:hover .nav-link {
  color: #fff;
  border-color: transparent;
}
.mobile-menu-container .nav-item .nav-link.active,
.mobile-menu-container .nav-item.show .nav-link {
  color: #fe7c00;
  border-color: #fe7c00;
}
.mobile-menu-container .nav-item + .nav-item {
  margin-left: 1px;
}
.mobile-menu-container.scrollable::-webkit-scrollbar-thumb,
.mobile-menu-container .sidebar-content::-webkit-scrollbar-thumb {
  margin-right: 2px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  cursor: pointer;
}
.mobile-menu {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: -0.025em;
  color: #e1e1e1;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: -0.025em;
}
.mobile-menu ul {
  display: none;
  width: 100%;
}
.mobile-menu > li:first-child {
  padding-top: 0.5rem;
}
.mobile-menu > li:last-child {
  padding-bottom: 0.5rem;
}
.mobile-menu li a {
  display: block;
  position: relative;
  padding: 5px 0;
  display: flex;
    justify-content: space-between;
}
.mobile-menu li a span::before{
  font-size: 8px !important;
}

.mobile-menu li i {
  display: inline-block;
  margin-bottom: 1px;
  font-size: 2rem;
  margin-right: 1rem;
  line-height: 0;
  vertical-align: middle;
}
.mobile-menu li li a {
  padding-left: 5px;
}
.mobile-menu li li li a {
  padding-left: 3.6rem;
}
.mobile-menu li:not(:last-child) {
  border-bottom: 1px solid #2e3237;
}
.mobile-menu-title {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 700;
  color: #fff;
}
.mobile-menu-overlay {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #000;
  opacity: 0;
  transition: opacity 0.4s;
}
.mobile-menu-close {
  position: fixed;
  left: calc(100vw - 50px);
  top: 25px;
  z-index: 10001;
  transition: opacity 0.3s;
  opacity: 0;
}
.mobile-menu-close i {
  font-size: 2.8rem;
  color: #e1e1e1;
}
.mmenu-anim {
  transform: translateY(30%);
}
.mmenu-anim,
.mmenu-anim > li {
  transition: transform 0.4s, opacity 0.3s;
  transition-timing-function: cubic-bezier(0.5, 0, 0.3, 1);
}
.mmenu-anim > li:nth-child(0) {
  opacity: 0;
  transform: translateY(0px);
}
.mmenu-anim > li:first-child {
  opacity: 0;
  transform: translateY(50px);
}
.mmenu-anim > li:nth-child(2) {
  opacity: 0;
  transform: translateY(100px);
}
.mmenu-anim > li:nth-child(3) {
  opacity: 0;
  transform: translateY(150px);
}
.mmenu-anim > li:nth-child(4) {
  opacity: 0;
  transform: translateY(200px);
}
.mmenu-anim > li:nth-child(5) {
  opacity: 0;
  transform: translateY(250px);
}
.mmenu-anim > li:nth-child(6) {
  opacity: 0;
  transform: translateY(300px);
}
.mmenu-anim > li:nth-child(7) {
  opacity: 0;
  transform: translateY(350px);
}
.mmenu-anim > li:nth-child(8) {
  opacity: 0;
  transform: translateY(400px);
}
.mmenu-anim > li:nth-child(9) {
  opacity: 0;
  transform: translateY(450px);
}
.mmenu-anim > li:nth-child(10) {
  opacity: 0;
  transform: translateY(500px);
}
.mmenu-anim > li:nth-child(11) {
  opacity: 0;
  transform: translateY(550px);
}
.mmenu-anim > li:nth-child(12) {
  opacity: 0;
  transform: translateY(600px);
}
.mmenu-active {
  overflow: hidden;
}
.mmenu-active .mmenu-anim,
.mmenu-active .mmenu-anim > li {
  opacity: 1;
  transform: translateY(0);
}
.mmenu-active .page-wrapper {
  margin-left: 296px;
  margin-right: -296px;
}
.mmenu-active .mobile-menu-wrapper {
  visibility: visible;
}
.mmenu-active .mobile-menu-container {
  transform: translateX(0);
}
.mmenu-active .mobile-menu-overlay {
  opacity: 0.8;
}
.mmenu-active .mobile-menu-close {
  opacity: 1;
}
@media (max-width: 400px) {
  .mobile-menu-close {
    left: calc(100vw - 40px);
    top: 10px;
  }
}
.category-menu {
  background: #fef0e3;
}
.category-menu .menu-title {
  padding: 1.8rem 0.2rem 1.8rem;
  margin: 0;
  text-transform: capitalize;
  font-size: 1.8rem;
  font-weight: 700;
  font-family: poppins;
  line-height: 1;
  letter-spacing: -0.05em;
  color: #222;
}
.category-menu .menu-body:last-child li:last-child {
  border: 0;
}
.category-menu i {
  vertical-align: middle;
  line-height: 0;
  margin-right: 1rem;
  padding-left: 0.2rem;
  font-size: 2rem;
  color: #666;
}
.category-menu > li > a {
  display: block;
  padding: 1.2rem 0 1.3rem;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.46;
  letter-spacing: -0.01em;
  text-transform: capitalize;
  color: #675545;
}
.category-menu > li:not(:last-child) > a {
  border-bottom: 1px solid #f0e0d1;
}
.category-menu li:hover > a:not(.menu-title) {
  color: #fe7c00;
}
.category-menu li:hover > a:not(.menu-title) i {
  color: inherit;
}
.category-menu .submenu > a:after {
  font-size: 1rem;
}
.category-menu .submenu .megamenu {
  min-width: 68.5rem;
  padding: 0 0 0 0.9rem;
}
.category-menu .submenu .megamenu > li {
  padding: 0 1rem;
  flex: 1;
}
.category-menu .submenu .megamenu > li:last-child {
  padding-right: 0;
  flex: none;
}
.category-menu .submenu .megamenu .menu-title {
  padding: 2.7rem 0rem 0.2rem;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: -0.025em;
}
.category-menu .submenu .megamenu .divider {
  height: 1px;
  width: 100%;
  margin: 0;
}
.category-menu .submenu .megamenu ul {
  position: relative;
  left: 0;
  top: 0;
  padding: 0;
  font-size: 1.3rem;
  opacity: 1;
  visibility: visible;
  box-shadow: none;
  min-width: auto;
  z-index: auto;
  transform: none;
}
.category-menu .submenu .megamenu ul li {
  padding: 0;
  line-height: 1.2;
}
.category-menu .submenu .megamenu ul li a {
  padding: 0.7rem 0;
}
.category-menu .submenu .megamenu .menu-banner .banner-content {
  left: 3rem;
}
.category-menu .submenu .megamenu .menu-banner .banner-subtitle {
  font-size: 1.4rem;
}
.category-menu .submenu .megamenu .menu-banner .banner-title {
  font-size: 2.4rem;
  line-height: 1.2;
  margin-bottom: 2.8rem;
}
.category-menu .submenu .megamenu .menu-banner .btn-md {
  padding: 0.7em 1.41em 0.7em 1.41em;
}
.category-menu .submenu .megamenu .menu-banner5 .banner-content {
  top: 2.6rem;
}
.category-menu .submenu .megamenu .menu-banner5 .banner-subtitle {
  margin-bottom: 1.1rem;
}
.category-menu .submenu .megamenu .menu-banner5 .banner-title {
  line-height: 1.25;
}
.category-menu .submenu .megamenu.type2 {
  display: block;
  min-width: 88rem;
  padding: 0 1rem 2rem;
}
.category-menu .submenu .megamenu.type2 .menu-title {
  padding-top: 1.5rem;
}
.category-menu .submenu .megamenu.type2 figure {
  text-align: center;
}
.category-menu ul {
  padding: 2rem 0;
  background: #fff;
  font-family: poppins;
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: 0;
  color: #666;
}
@media (max-width: 1199px) {
  .category-menu .submenu .megamenu.type2 {
    min-width: 72rem;
  }
} 
.widget-newsletter .newsletter-info {
  max-width: 35rem;
  width: 100%;
}
.widget-newsletter .input-wrapper-inline {
  height: 4.9rem;
}
.widget-newsletter .input-wrapper {
  max-width: 55.3rem;
}
.widget-newsletter input {
  padding: 0 0 0 2.3rem;
  min-height: 100%;
  border: 0;
  border-radius: 0.3rem 0 0 0.3rem;
  color: #666;
  background: #fff;
}
.widget-newsletter .btn {
  padding: 1.2em 1.33em 1.07em;
}
.widget-newsletter .btn i {
  font-size: 1.6rem;
  margin: -0.4rem 0 0 0.6rem;
}
.widget-newsletter .widget-title {
  padding: 0;
  margin-bottom: 0;
  font-family: poppins;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  text-transform: inherit;
  line-height: 1;
  color: #fff;
}
.widget-newsletter p {
  margin-bottom: 0.1rem;
  font-family: poppins;
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 1.8;
  color: #777;
}
.footer .widget-category .category-box {
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  margin-bottom: 2rem;
}
.footer .widget-category .category-name {
  margin-bottom: 0;
  margin-right: 2.4rem;
  font-size: 1.4rem;
  letter-spacing: 0;
  color: #ccc;
}
.footer .widget-category a {
  display: inline-block;
  position: relative;
  margin-right: 2rem;
  font-size: 1.3rem;
  font-weight: 400;
  color: #999;
}
.footer .widget-category a:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  border-bottom: 1px solid;
  transform: scaleX(0);
  transition: transform 0.3s;
}
.footer .widget-category a:hover:before {
  transform: scale(1);
}
.footer .widget-category a:not(:last-child):after {
  content: "";
  position: absolute;
  height: 14px;
  margin-left: 1rem;
  top: 50%;
  border-left: 1px solid #454545;
  transform: translateY(-50%);
}
.footer .widget-about .logo-footer {
  display: block;
  margin-bottom: 1.6rem;
}
.footer .widget-about p {
  margin-bottom: 3rem;
  color: #999;
  line-height: 1.85;
  letter-spacing: -0.025em;
}
.footer .widget-about .widget-body {
  padding: 0;
}
.payment img {
  display: block;
}
.sticky-footer {
  display: flex;
}
.sticky-footer > * {
  flex: 1;
}
.sticky-footer .search-toggle {
  padding: 0;
  color: inherit;
}
.sticky-footer .header-search.show,
.sticky-footer .header-search:hover {
  color: #222;
}
.sticky-footer .hs-toggle .input-wrapper {
  min-width: 29rem;
  right: 1.5rem;
  margin-bottom: 2rem;
}
.sticky-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem 0 1.3rem;
}
.sticky-link i {
  width: 2.3rem;
  height: 2.4rem;
  text-align: center;
  font-size: 2.3rem;
  line-height: 1;
  transform: translateY(0);
  transition: transform 0.35s ease;
}
.sticky-link i.d-icon-search {
  font-size: 1.9rem;
}
.sticky-link span {
  margin-top: 0.8rem;
  font-size: 1rem;
  line-height: 1;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}
.sticky-link.active {
  color: #222;
}
.sticky-link:hover {
  color: #222;
}
.sticky-link:hover i {
  transform: translateY(-5px);
}
@media (min-width: 576px) {
  .sticky-footer {
    padding: 0 4rem;
  }
}
@media (min-width: 768px) {
  .sticky-footer {
    display: none;
  }
}
@media (max-width: 991px) {
  .footer-middle .row > div:last-child .widget {
    margin-bottom: 1rem;
  }
}
.shop-banner-default .banner-subtitle,
.shop-boxed-banner .banner-subtitle {
  margin-bottom: 0.6rem;
  font-size: 2em;
}
.shop-banner-default .banner-title,
.shop-boxed-banner .banner-title {
  margin-bottom: 2.4rem;
  font-size: 4em;
  letter-spacing: -0.035em;
}
.shop-banner-default .btn,
.shop-boxed-banner .btn {
  padding: 1.2em 2.8em;
  border-color: #919eb2;
}
.shop-banner-default .btn i,
.shop-boxed-banner .btn i {
  vertical-align: middle;
  margin-left: 0.7rem;
  font-size: 2.2rem;
}
.shop-banner-default .btn:active,
.shop-banner-default .btn:focus,
.shop-banner-default .btn:hover,
.shop-boxed-banner .btn:active,
.shop-boxed-banner .btn:focus,
.shop-boxed-banner .btn:hover {
  border-color: #fff;
}
.shop-banner-default {
  padding: 7.5rem 5.7% 8.3rem;
}
.shop-boxed-banner {
  padding: 7.4rem 7% 8.4rem;
}
.cph-header {
  min-height: 350px;
  height: auto;
}
.cph-header .category img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.cph-header .category-icon {
  padding: 1.7rem 1rem;
}
.toolbox,
.toolbox-item,
.toolbox-item-full,
.toolbox-left,
.toolbox-right {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.toolbox {
  position: relative;
  z-index: 21;
  justify-content: space-between;
}
.toolbox.toolbox-pagination {
  z-index: 20;
}
.toolbox {
  font-size: 1.3rem;
  line-height: 1.5;
  padding: 3.5rem 0.2rem 1rem;
  transition: margin 0.4s;
  background-color: transparent;
}
.toolbox.sticky-toolbox {
  padding-top: 3rem;
}
.toolbox-left > :not(:last-child),
.toolbox-right > :not(:last-child),
.toolbox > :not(:last-child) {
  margin-right: 1.8rem;
}
.toolbox label {
  margin-right: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
}
.toolbox select {
  cursor: pointer;
  padding-top: 0.85rem;
  padding-bottom: 0.85rem;
  border-color: #ebebeb;
  z-index: 0;
}
.toolbox .select-box .form-control {
  color: #222;
}
.toolbox .select-box:before {
  font-size: 1rem;
  right: 1rem;
  z-index: 1;
}
.toolbox .toolbox-sort .form-control {
  max-width: 15rem;
  padding-left: 1.4rem;
}
.toolbox .toolbox-sort:before {
  right: 1.4rem;
  z-index: 2;
}
.toolbox-item {
  flex-wrap: nowrap;
  margin-bottom: 1rem;
}
.toolbox-item label {
  color: #222;
}
.toolbox-item-full {
  flex-basis: 100%;
}
.btn-layout {
  color: #dadada;
  font-size: 22px;
  padding: 0 2px;
}
.btn-layout:last-child {
  padding-right: 0;
}
.btn-layout.active,
.btn-layout:hover {
  color: #333;
}
.btn-layout + .btn-layout {
  margin-left: 2px;
}
.select-menu {
  color: #222;
}
.select-menu:before {
  right: 1.25em;
  font-size: 1.2rem;
  margin-top: 1px;
}
.select-menu ul,
.select-menu ul:before {
  content: "";
  position: absolute;
  border: 1px solid #eee;
  width: 11px;
}
.select-menu ul {
  top: 100%;
  z-index: 1000;
  width: 18rem;
  margin-top: 1rem;
  padding: 1rem 1.4rem 1rem;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
}
.select-menu ul:before {
  top: -6px;
  left: 22px;
  height: 11px;
  border-width: 1px 0 0 1px;
  background-color: #fff;
  transform: rotate(45deg);
}
.select-menu.opened > ul {
  opacity: 1;
  visibility: visible;
}
.dark-theme .select-menu {
  color: #999;
}
.dark-theme select.form-control {
  background-color: transparent;
}
.dark-theme .toolbox-item label {
  color: #999;
}
.dark-theme .toolbox .select-box .form-control {
  color: #999;
}
.dark-theme .btn-layout {
  color: #666;
}
.dark-theme .btn-layout.active,
.dark-theme .btn-layout:hover {
  color: #fe7c00;
}
.select-menu-toggle,
.select-menu select {
  position: relative;
  border: 1px solid #ebebeb;
  padding: 0.8rem 4rem 0.8rem 1.4rem;
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: -0.03em;
}
.select-menu-toggle {
  border-radius: 2px;
}
.select-menu select {
  max-width: 17.2rem;
  padding: 0.85rem 2.6rem 0.85rem 0.9rem;
}
.select-item {
  padding: 6px 0.7rem 4px 1.2rem;
  color: #222;
  background: #eee;
  letter-spacing: -0.01em;
}
.select-item i {
  padding: 0.5rem 0.6rem 0.7rem;
  margin-left: 5px;
  font-size: 9px;
  vertical-align: middle;
}
.select-items {
  display: none;
}
.toolbox + .select-items {
  font-size: 1.2rem;
  margin: 2px 0 1.8rem;
}
.select-items > * {
  display: inline-block;
  margin: 0 0.8rem 0.5rem 0;
}
.select-items .filter-clean {
  margin-left: 1.2rem;
}
.sticky-content-wrapper + .select-items {
  font-size: 1.2rem;
  margin: 2px 0 1.8rem;
}
.toolbox-pagination {
  position: relative;
  margin-bottom: 1rem;
  padding: 2.5rem 0.2rem;
  border-top: 1px solid #e1e1e1;
}
.toolbox-horizontal .show-info {
  color: #999;
  font-size: inherit;
}
.toolbox-horizontal.fixed .sidebar-content,
.toolbox-horizontal.fixed .sidebar-overlay {
  min-height: 100vh;
}
.left-sidebar-toggle,
.right-sidebar-toggle,
.sidebar-content .filter-actions .sidebar-toggle-btn,
.toolbox .top-sidebar-toggle {
  padding: 0.61em 0.92em 0.61em;
  margin-right: 2rem;
  font-weight: 700;
  line-height: 1.2;
}
.left-sidebar-toggle .d-icon-filter-2,
.right-sidebar-toggle .d-icon-filter-2,
.sidebar-content .filter-actions .sidebar-toggle-btn .d-icon-filter-2,
.toolbox .top-sidebar-toggle .d-icon-filter-2 {
  font-size: 1.4rem;
}
.left-sidebar-toggle .d-icon-filter-2:before,
.right-sidebar-toggle .d-icon-filter-2:before,
.sidebar-content .filter-actions .sidebar-toggle-btn .d-icon-filter-2:before,
.toolbox .top-sidebar-toggle .d-icon-filter-2:before {
  font-weight: 600;
}
.toolbox .toolbox-left .left-sidebar-toggle {
  font-weight: 600;
}
.toolbox-item.right-sidebar-toggle {
  margin-right: 0;
}
.toolbox-wrap {
  display: flex;
  flex-direction: column-reverse;
  line-height: 1.3;
}
.toolbox-wrap .toolbox > * {
  line-height: 37px;
}
.toolbox-wrap .toolbox-layout {
  display: flex;
}
.toolbox-wrap option {
  text-transform: none;
}
.toolbox-wrap .toolbox-layout {
  display: flex;
}
.toolbox-wrap .sidebar-content .widget-title {
  padding-bottom: 1rem;
}
.sticky-toolbox.fixed {
  position: fixed;
  background-color: #fff;
  padding: 1rem 2rem 0;
}
.widget .widget-body .filter-actions {
  display: block;
  padding: 0 0 1.5rem 0.2rem;
}
.filter-price-text {
  font-size: 1.3rem;
  color: #222;
}
.filter-items a:before {
  content: "";
  position: absolute;
  border: 1px solid #999;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  top: 50%;
  transform: translateY(-50%);
  left: -2.7rem;
  font-size: 0.7em;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  text-align: center;
  text-indent: 1px;
  line-height: 1.7em;
}
@media (-webkit-min-device-pixel-ratio: 1.5),
  (min--moz-device-pixel-ratio: 1.5),
  (min-device-pixel-ratio: 1.5) {
  .filter-items a:before {
    text-indent: 0;
  }
}
.toolbox .btn-link {
  padding: 0;
}
.filter-clean {
  font-size: 1.4rem;
  margin-left: auto;
  color: #222;
  transition: color 0.3s;
}
.filter-clean:hover {
  color: #fe7c00;
}
.shop-sidebar {
  position: relative;
}
.shop-sidebar .btn-outline:not(:hover) {
  color: #fe7c00;
  background-color: #fff;
}
.shop-sidebar .left-sidebar-toggle,
.shop-sidebar .sidebar-toggle-btn {
  padding: 0.8em 1.04em;
}
.shop-sidebar .filter-actions {
  display: flex;
  align-items: center;
  padding-right: 0.6rem;
  margin-bottom: 2rem;
}
.btn-icon-right i {
  margin-left: 0.8rem;
  font-size: 1.8rem;
}
@media (min-width: 576px) {
  .toolbox-horizontal .toolbox-left {
    margin-right: auto;
  }
  .mr-sm-auto {
    margin-right: auto !important;
  }
}
@media (min-width: 992px) {
  .sidebar-fixed {
    transition: margin 0.4s;
  }
  .sidebar-fixed .filter-actions:first-child {
    padding-top: 3rem;
  }
  .sidebar-fixed + .main-content {
    transition: flex-basis 0.4s, max-width 0.4s;
  }
  .sticky-sidebar-wrapper.closed {
    height: 0;
  }
  .closed.sidebar {
    margin-left: -25%;
  }
  .closed.right-sidebar {
    margin-right: -25%;
  }
  .closed + .main-content {
    max-width: 100%;
    flex-basis: 100%;
  }
  .main-content-wrap {
    overflow: hidden;
  }
  .sidebar-toggle-remain .toggle-remain {
    position: absolute;
    transition: 0.4s;
    left: 0;
  }
}
@media (min-width: 992px) and (-ms-high-contrast: active),
  (min-width: 992px) and (-ms-high-contrast: none) {
  .sidebar-toggle-remain .toggle-remain {
    top: 2.7rem;
  }
}
@media (min-width: 992px) {
  .sidebar-toggle-remain .filter-clean {
    line-height: 3.7rem;
  }
}
@media (min-width: 992px) {
  .sidebar-toggle-remain.sidebar .toggle-remain i {
    transition: transform 0.3s;
  }
  .sidebar-toggle-remain.sidebar.closed .toggle-remain {
    left: calc(100% + 30px);
  }
  .sidebar-toggle-remain.sidebar.closed .toggle-remain i {
    transform: rotateY(180deg);
  }
  .sidebar-toggle-remain.sidebar.closed + * > .toolbox:first-child {
    margin-left: 10.8rem;
  }
  .sidebar-toggle-remain.sidebar .toggle-remain:before {
    content: "";
    position: absolute;
    background-color: #fff;
    right: calc(100% + 2px);
    width: 20px;
    bottom: -2px;
    top: -2px;
  }
}
@media (min-width: 992px) {
  .sidebar-toggle-remain.right-sidebar.closed .toggle-remain {
    left: -3rem;
    transform: translateX(-100%);
  }
  .sidebar-toggle-remain.right-sidebar.closed .toggle-remain i:before {
    content: "";
  }
  .sidebar-toggle-remain.right-sidebar.closed + * > .toolbox:first-child {
    padding-right: 11rem;
  }
}
@media (min-width: 992px) {
  .toolbox-wrap .sidebar-content {
    border-top: 3px solid #eee;
    transition: border 0.3s;
  }
  .toolbox-wrap .sidebar-content > div {
    border-bottom: 3px solid #eee;
  }
  .toolbox-wrap .sidebar-content .widget {
    border: none;
  }
}
@media (min-width: 992px) {
  .shop-sidebar .filter-actions {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .toolbox .sidebar-content {
    max-width: none;
  }
}
@media (min-width: 992px) {
  .toolbox-horizontal .sidebar-fixed .sidebar-content {
    padding-bottom: 0;
  }
  .toolbox-horizontal .widget {
    position: relative;
    margin-bottom: 1rem;
    color: #222;
    border-bottom: none;
  }
  .toolbox-horizontal .widget .widget-title {
    position: relative;
    border: 1px solid #ebebeb;
    padding: 8px 4rem 8px 1.4rem;
    margin-bottom: 0;
    font-size: 1.2rem;
    line-height: 1.5;
    font-weight: 400;
    text-transform: capitalize;
    border-radius: 2px;
  }
  .toolbox-horizontal .widget:before {
    content: "";
    font-family: "Font Awesome 5 free";
    position: absolute;
    top: 50%;
    right: 1.25em;
    margin-top: 1px;
    transform: translateY(-50%);
    font-size: 1.2rem;
    font-weight: 900;
  }
  .toolbox-horizontal .widget.opened > ul {
    opacity: 1;
    visibility: visible;
  }
  .toolbox-horizontal .widget ul,
  .toolbox-horizontal .widget ul:before {
    content: "";
    position: absolute;
    border: 1px solid #eee;
  }
  .toolbox-horizontal .widget ul {
    top: 100%;
    z-index: 1000;
    width: 21rem;
    margin-top: 1rem;
    padding: 1rem 1.4rem;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
  }
  .toolbox-horizontal .widget ul:before {
    top: -6px;
    left: 22px;
    height: 11px;
    width: 11px;
    border-width: 1px 0 0 1px;
    background-color: #fff;
    transform: rotate(45deg);
  }
  .toolbox-horizontal .widget ul li {
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 992px) {
  .toolbox-wrap .filter-actions:first-child {
    padding: 0;
  }
  .toolbox-wrap .sidebar-toggle-btn {
    display: none;
  }
  .toolbox-wrap > .closed {
    margin: 0;
  }
  .toolbox-wrap > .closed .sidebar-content {
    display: none;
    border-color: transparent;
    background: transparent;
  }
  .toolbox-wrap .filter-clean {
    display: none;
    position: absolute;
    right: 0;
    top: -40px;
  }
}
@media (min-width: 1200px) {
  .select-menu:not(:last-child):not(.toolbox-show) {
    margin-right: 2rem;
  }
  .toolbox .shop-sidebar {
    margin-right: 2rem;
  }
}
@media (max-width: 991px) {
  .toolbox .sidebar-fixed {
    margin-right: 0;
  }
  .toolbox .sidebar-content {
    display: block;
  }
  .sidebar-content .select-menu {
    display: block;
    margin: 0;
    font-size: 1.4rem;
  }
  .sidebar-content .select-menu ul {
    position: static;
    width: 100%;
    padding: 0;
    border: 0;
    color: #666;
    margin: 0 0 1.1rem;
    opacity: 1;
    visibility: visible;
  }
  .sidebar-content .select-menu ul a:before {
    width: 18px;
    height: 18px;
    margin-top: 0;
  }
  .sidebar-content .select-menu:before,
  .sidebar-content .select-menu > a:before,
  .sidebar-content .select-menu ul:before {
    content: none;
  }
  .sidebar-content .select-menu-toggle {
    display: block;
    border-width: 3px 0 0;
    padding: 2rem 2px 1.6rem;
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: capitalize;
  }
  .sidebar-content .toolbox-item:first-child a {
    border: none;
  }
}
@media (max-width: 767px) {
  .toolbox-item.show-info,
  .toolbox-item > span,
  .toolbox-item label {
    display: none;
  }
}
@media (max-width: 575px) {
  .toolbox-item.select-box {
    margin-right: 0;
  }
  .toolbox-item.select-box ~ .toolbox-item.right-sidebar-toggle {
    margin-left: 1rem;
  }
  .toolbox-item.toolbox-layout {
    display: none;
  }
  .sticky-sidebar .filter-actions .sidebar-toggle-btn,
  .toolbox .toolbox-left .left-sidebar-toggle {
    margin-right: 1rem;
  }
  .toolbox .toolbox-left {
    margin: 0;
  }
  .toolbox .left-sidebar-toggle {
    margin-right: 0.5rem;
  }
  .toolbox .toolbox-sort .form-control {
    max-width: 12.8rem;
  }
  .toolbox-horizontal {
    justify-content: flex-start;
    flex-direction: row;
  }
  .toolbox-horizontal .toolbox-left {
    margin-right: auto;
  }
  .toolbox-horizontal .toolbox-right {
    margin-left: auto;
  }
  .toolbox-horizontal .toolbox-item.toolbox-show {
    margin-right: 0;
  }
  .toolbox-pagination {
    flex-direction: column;
  }
  .toolbox-pagination > .show-info {
    margin-right: 0;
  }
  .toolbox-pagination > :not(:last-child) {
    margin-bottom: 1rem;
  }
}
@media (max-width: 479px) {
  .sticky-toolbox.fixed {
    padding: 1rem 1.5rem 0;
  }
  .toolbox {
    padding-right: 0;
    padding-left: 0;
  }
  .toolbox .toolbox-sort .form-control {
    max-width: 12.5rem;
    padding-left: 1rem;
  }
  .toolbox select {
    padding-top: 0.63rem;
    padding-bottom: 0.63rem;
    padding-right: 2rem;
  }
  .toolbox .btn-sm.toolbox-item,
  .toolbox .btn.toolbox-item {
    font-size: 1.2rem;
    padding: 0.61em 0.92em 0.61em;
  }
}

aside .service-list {
  padding: 0 2rem;
  border: 1px solid #eee;
}
aside .service-list > * {
  justify-content: flex-start;
  padding: 2.2rem 0;
}
aside .service-list > :not(:last-child) {
  border-bottom: 1px solid #e1e1e1;
}
aside .service-list i {
  margin-left: 5px;
  font-size: 3.2rem;
}
aside .service-list .icon-box-title {
  font-size: 1.5rem;
  letter-spacing: 0;
  line-height: 1.2;
}
aside .service-list p {
  line-height: 1.2;
}
aside .service-list .icon-box1 i {
  margin-left: 0;
  font-size: 3.7rem;
}
aside .banner-content {
  left: 5%;
  top: 12%;
  width: 90%;
}
aside .banner-subtitle {
  font-weight: 500;
  font-size: 16px;
  color: #777777;
}
aside .banner-title {
  font-size: 2.3rem;
  line-height: 1.4em;
}
aside .owl-nav-top .owl-nav {
  top: -5.1rem;
}
aside .owl-nav-top .owl-nav i {
  padding: 0.1rem;
  font-size: 1.2rem;
}
aside .owl-nav-top .owl-nav i:before {
  font-weight: 600;
}
 
.avg-rating-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.avg-rating-container mark {
  font-size: 6rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  line-height: 1;
  color: #fe7c00;
  background: none;
  margin-right: 1.8rem;
}
.avg-rating-container .rating-reviews:hover {
  color: #999;
}
.ratings-item {
  display: flex;
  align-items: center;
}
.ratings-item .ratings-container {
  margin-right: 1.8rem;
}
.ratings-item .progress-value {
  margin-left: 2rem;
}
.rating-percent {
  background: #eee;
  height: 0.8rem;
  width: 20rem;
  max-width: 100%;
  border-radius: 1rem;
}
.rating-percent span {
  display: block;
  height: 100%;
  border-radius: inherit;
  background: #999;
}
.comments .toolbox {
  padding: 0 0 1rem;
  margin-bottom: 3rem;
  border-bottom: 1px solid #e1e1e1;
}
.comments .toolbox .btn {
  font-size: 1.3rem;
  padding: 0.82em 1.08em;
}
.comments .toolbox .toolbox-sort .form-control {
  min-width: 15rem;
}
.comments .toolbox-pagination {
  border: none;
}
.comments .page-item {
  margin-bottom: 0;
}
.comments .file-input-wrappers {
  display: flex;
}
.comments .file-input-wrappers > * {
  width: 6rem;
  height: 6rem;
  margin-right: 1rem;
  cursor: zoom-in;
}
.comments .file-input-wrappers img {
  height: 100%;
}
.comments .feeling i {
  font-size: 1.4rem;
}
.comments .btn-play {
  display: flex;
  justify-content: center;
  align-items: center;
}
.comments .btn-play i {
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
}
.review-form-section .review-form-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: -100%;
  background-color: #fff;
  z-index: 1101;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.1);
  padding: 3rem;
  transition: right 0.3s;
  overflow: auto;
}
.review-form-section .review-overlay {
  position: fixed;
  left: 0;
  width: 100vw;
  top: -10vh;
  height: 120vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1100;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
}
.review-form-section.opened .review-form-wrapper {
  right: 0;
}
.review-form-section.opened .review-overlay {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 575px) {
  #product-tab-reviews .comments-list > ul,
  .card-body .comments-list > ul {
    padding: 0;
  }
  #product-tab-reviews .comment-body,
  .card-body .comment-body {
    padding-left: 1.5rem;
  }
  #product-tab-additional label {
    min-width: 13rem;
  }
}
@media (min-width: 576px) {
  #product-tab-size-guide .size-table tbody th,
  #product-tab-size-guide thead th:first-child,
  .card-sizeguide .size-table tbody th,
  .card-sizeguide thead th:first-child {
    padding-left: 3rem;
  }
  #product-tab-size-guide {
    display: flex;
  }
}
@media (min-width: 992px) {
  .product-form select {
    width: 20rem;
  }
  .product-single .product-details {
    padding-left: 1rem;
  }
  .product-details.row {
    padding-left: 0;
  }
  .product-details.row > :last-child {
    padding-left: 2rem;
  }
}
@media (min-width: 1600px) {
  .container-fluid .product-thumbs.owl-carousel {
    width: calc(100% + 20px);
    margin: 0 -10px;
  }
  .container-fluid .product-thumb {
    margin: 0 10px;
  }
  .container-fluid .product-thumbs-wrap {
    margin-top: 20px;
  }
  .container-fluid .product-thumbs .owl-prev {
    left: 10px;
  }
  .container-fluid .product-thumbs .owl-next {
    right: 10px;
  }
  .container-fluid .pg-vertical .product-thumb {
    margin: 0 0 20px;
  }
  .container-fluid .pg-vertical .product-thumbs-wrap {
    margin: 0 20px 0 0;
  }
  .container-fluid .pg-vertical .product-single-carousel {
    max-width: calc(100% - 129px);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  aside .service-list {
    padding: 0 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1035px) {
  .product-single #product-tab-size-guide {
    display: block;
  }
}
@media (max-width: 991px) {
  aside .service-list .icon-box-side {
    flex-direction: row;
  }
  aside .service-list .icon-box-side .icon-box-icon {
    padding: 0;
    margin: 0 2rem 0 0;
  }
  aside .service-list .icon-box-side .icon-box-content {
    text-align: left;
  }
}
.product-sticky-both .btn-cart {
  margin-bottom: 1rem;
}
.product-sticky-both .product-action {
  display: flex;
}
.product-sticky-both .btn-wishlist {
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .product-sticky-both .btn-cart {
    max-width: 100%;
  }
  .product-sticky-both .product-form .p-relative,
  .product-sticky-both .product-form .select-box {
    margin-right: 0;
    width: 100%;
  }
  .product-sticky-both .product-form select {
    flex: 1;
    width: 100%;
  }
}
@media (max-width: 991px) {
  #product-tab-description .icon-box-side,
  .card-body .icon-box-side {
    flex-direction: row;
  }
  #product-tab-description .icon-box-icon,
  .card-body .icon-box-icon {
    display: inline-flex;
    margin-bottom: 0;
    padding: 0;
  }
  #product-tab-description .icon-box-content,
  .card-body .icon-box-content {
    text-align: left;
  }
  .review-form-wrapper {
    width: 45rem;
  }
}
@media (max-width: 575px) {
  .review-form-wrapper {
    width: 30rem;
  }
  .review-form-wrapper .file-input-wrapper {
    width: 6rem;
    height: 6rem;
  }
  .review-form-wrapper .file-input {
    margin-right: 1rem;
  }
}
.dark-theme .avg-rating-title,
.dark-theme .product-nav .product-name,
.dark-theme .progress-value {
  color: #999;
}
.dark-theme .size-image img {
  filter: invert(1);
}
.dark-theme .size-table {
  color: #fff;
}
.dark-theme .size-table thead th {
  background: #999;
}
.dark-theme .size-table tbody tr:nth-child(2n) {
  background-color: #999;
}
.dark-theme .rating-percent {
  background: #999;
}
.dark-theme .rating-percent span {
  background: #fff;
}
.store-listing1 .toolbox,
.store-listing .toolbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-shadow: 1px 1px 20px 0px #e9e9e9;
  background-color: #fff;
}
.store-listing1 .toolbox .btn,
.store-listing .toolbox .btn {
  margin-right: 1.9rem;
  padding: 0.6em 0.9em 0.64em;
}
.store-listing1 .toolbox .btn i,
.store-listing .toolbox .btn i {
  font-size: 1.5rem;
  margin-right: 0.8rem;
}
.store-listing1 .toolbox-item,
.store-listing .toolbox-item {
  margin-bottom: 0;
}
.store-listing1 .toolbox-item.toolbox-sort,
.store-listing .toolbox-item.toolbox-sort {
  margin-right: 1.3rem;
}
.store-listing1 .toolbox-item .form-control,
.store-listing .toolbox-item .form-control {
  min-width: 15.2rem;
  border-color: #e1e1e1;
}
.store-listing1 .toolbox-item label,
.store-listing .toolbox-item label {
  margin-right: 1rem;
}
.store-listing1 .store-count,
.store-listing .store-count {
  margin: 0 0 0.4rem;
  color: #222;
}
.store-listing1 .btn-layout,
.store-listing .btn-layout {
  margin: 0;
  font-size: 1.9rem;
  color: #999;
}
.store-listing1 .btn-layout.active,
.store-listing1 .btn-layout:hover,
.store-listing .btn-layout.active,
.store-listing .btn-layout:hover {
  color: #222;
}
.store-listing1 .form-wrapper,
.store-listing .form-wrapper {
  display: none;
  position: relative;
  background-color: #fff;
  margin-top: 3.2rem;
  padding: 3.2rem 2rem;
  box-shadow: 1px 1px 20px 0px #e9e9e9;
}
.store-listing1 .form-wrapper:before,
.store-listing .form-wrapper:before {
  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  bottom: 100%;
  border-right: 13px solid transparent;
  border-bottom: 21px solid #fff;
  border-left: 13px solid transparent;
}
.store-listing1 .form-wrapper .input-wrapper-inline,
.store-listing .form-wrapper .input-wrapper-inline {
  max-width: 100%;
  height: 4.2rem;
}
.store-listing1 .form-wrapper .form-control,
.store-listing .form-wrapper .form-control {
  margin-right: 1rem;
  min-height: 4.2rem;
  border-color: #eee;
}
.store-listing1 .form-wrapper .btn,
.store-listing .form-wrapper .btn {
  padding: 0.7em 1.63em;
  text-transform: capitalize;
  font-weight: 400;
}
.store-listing1 .store,
.store-listing1 .store-banner img,
.store-listing .store,
.store-listing .store-banner img {
  min-height: 29rem;
}
.store-listing1 .store-header {
  max-width: 27.6rem;
  flex: 0 0 27.6rem;
}
.store-listing1 .store,
.store-listing1 .store-banner img {
  min-height: 14rem;
  width: 100%;
}
.store-listing1 .store-content {
  padding: 1.2rem 1rem 1.4rem 4%;
  color: #666;
}
.store-listing1 .store-content p {
  position: relative;
  padding-left: 2.7rem;
  font-size: 1.5rem;
}
.store-listing1 .store-content p:before {
  position: absolute;
  content: "";
  font-family: "Font Awesome 5 Free";
  left: 0;
  top: 0;
  font-weight: 900;
  font-size: 1.6rem;
  line-height: 1.3;
  color: #0bb90b;
}
.store-listing1 .ratings-container {
  margin-bottom: 1.4rem;
}
.store-listing1 .store-title {
  margin-bottom: 1.2rem;
}
.store-listing1 .store-title a {
  font-size: 3.4rem;
}
.store-listing1 .store-footer {
  margin: 0 5.5rem 0.4rem 0;
  padding: 1rem 2rem;
}
.vendor .widget-collasible .cart-icon {
  margin-top: -0.4rem;
  right: 0.1rem;
  font-size: 1.7rem;
  color: #222;
}
.vendor .sidebar-content .widget-body {
  margin-bottom: 2rem;
}
.vendor .widget-contact-vendor .btn {
  margin: 1rem 0;
}
.vendor .widget-store-time {
  font-size: 1.3rem;
  color: #aaa;
}
.vendor .widget-store-time li:not(:last-child) {
  border-bottom: 1px solid #edeef0;
}
.vendor .widget-store-time li {
  padding: 8.5px 3px;
}
.vendor .widget-store-time label {
  color: #222;
  display: inline-block;
  min-width: 10.4rem;
  padding-right: 1rem;
  line-height: 2;
}
.vendor-store-banner > figure img {
  min-height: 34.5rem;
}
.vendor-store-content {
  top: 0;
  height: 100%;
  left: 0;
  max-width: 32rem;
  padding: 3rem;
  background-color: rgba(0, 0, 0, 0.65);
  width: 100%;
}
.vendor-store-content figure img {
  width: 8rem;
  height: 8rem;
}
.vendor-store-content .vendor-store-title {
  padding: 0;
  font-size: 2rem;
  margin: 1rem 0 1.8rem;
  color: #fff;
}
.vendor-store-content li {
  padding-bottom: 0.8rem;
}
.vendor-store-content li i {
  font-size: 1.8rem;
  width: 2rem;
  margin: 0 0.6rem 0 -3.1rem;
  text-align: center;
}
.vendor-store-content li span {
  display: inline-block;
}
.vendor-store-content ul {
  padding-left: 2.8rem;
  font-size: 1.4rem;
  font-weight: 400;
  color: #fff;
  opacity: 0.6;
  list-style: none;
  line-height: 1.86;
  letter-spacing: -0.025em;
}
@media (max-width: 991px) {
  .store-list {
    display: block;
    height: calc(100% - 2rem);
  }
  .store-list .store-footer {
    position: absolute;
  }
  .store-list .store-content {
    margin-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    height: auto;
  }
  .store-listing1 .store-header {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .store-listing1 .store-title a {
    font-size: 2.5rem;
  }
}
@media (max-width: 767px) {
  .vendor-store-content {
    max-width: 100%;
    background-color: rgba(0, 0, 0, 0.15);
  }
  .vendor-store-content ul {
    opacity: 1;
  }
  .store-listing .toolbox {
    display: block;
  }
  .store-listing .toolbox .btn {
    margin-right: 1rem;
  }
  .store-listing .toolbox p {
    font-size: 1.3rem;
  }
}
.header .divider {
  opacity: 0.4;
  max-height: 2rem;
}
.header .cart-toggle i,
.header .header-phone i,
.header .search-toggle i {
  font-size: 22px;
}
@media (max-width: 1199px) {
  .header .header-phone {
    display: none;
  }
}
@media (max-width: 1599px) {
  .header .menu.menu-active-underline > li {
    margin-right: 1.5rem;
  }
  .header .menu.menu-active-underline > li:last-child {
    display: none;
  }
}
.header .menu.menu-active-underline > li > a:before {
  bottom: 4px;
}
.header .menu.menu-active-underline > li > a,
.header .menu.menu-active-underline > li > a:before {
  color: #fff;
}

@media (min-width: 1600px) {
  .col-xxl-sidebar {
    max-width: 322px;
    flex: 0 0 322px;
  }
  .col-xxl-content {
    max-width: calc(100% - 322px);
    flex: 0 0 calc(100% - 322px);
  }
}
 
 
@media (max-width: 991px) {
 
}
.owl-dots-container {
  right: 7.4%;
  z-index: 1;
}
.owl-dots-container .owl-dot {
  position: relative;
  margin: 10px 0;
  padding: 1rem 1rem 0.7rem;
  border: none;
  border-radius: 50%;
  background-color: #d9d8d5;
  transition: background-color 0.3s;
}
.owl-dots-container .owl-dot img {
  transform: rotateZ(-10deg) translateX(-10px);
  transition: transform 0.3s;
}
.owl-dots-container .owl-dot:hover {
  cursor: pointer;
}
.owl-dots-container .owl-dot.active {
  background-color: #fff;
}
.owl-dots-container .owl-dot.active img {
  transform: rotateZ(0) translateX(0);
}
.owl-dots-container .owl-dot.active .dot-content {
  background-color: #fe7c00;
}
.owl-dots-container .owl-dot:hover {
  background-color: #fff;
}
.owl-dots-container .owl-dot:hover img {
  transform: rotateZ(0) translateX(0);
}
.owl-dots-container .dot-content {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  right: -1%;
  z-index: 2;
  background-color: #333;
  border-radius: 100%;
}
.owl-dots-container .dot-index {
  color: #fff;
  display: block;
  font-family: poppins;
  font-size: 16px;
  font-weight: 800;
  padding-top: 9px;
}
.intro-section .banner-title {
  font-size: 5em;
  line-height: 1.2;
}
.intro-section .banner-descri,
.intro-section .banner-subtitle {
  font-size: 2.4em;
}
.intro-section .intro-slider > figure img {
  object-fit: cover;
  min-height: 700px;
}
.intro-slide1 .banner-subtitle {
  font-size: 16px;
}
.intro-slide1 .banner-subtitle span {
  font-size: 1.25em;
}
.intro-slide1 .banner-title {
  max-width: 464px;
}
.intro-slide2 .banner-content {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.intro-slide2 .banner-content figure {
  width: 90%;
}
.intro-slide2 .banner-content img {
  max-width: 779px;
}
.intro-slide2 .banner-text > div {
  left: 16%;
}
.intro-slide3 .banner-content {
  right: 2.7%;
}
.intro-slide3 .banner-content .banner-subtitle {
  color: #cacbcd;
}
.intro-slide3 .banner-title {
  max-width: 570px;
}

.page-sidebar {
  z-index: 10;
  padding-left: 42px;
  border-right: 1px solid #e1e1e1;
}
.page-sidebar .widget-title {
  font-size: 20px;
  font-weight: 800;
  text-transform: capitalize;
  color: #222;
  padding: 16px 0;
  margin: 0;
  border: none;
}
.page-sidebar .category-menu {
  background-color: transparent;
}
.page-sidebar .menu > li {
  padding-left: 0;
}
.page-sidebar .menu > li > a {
  border: none;
  font-size: 14px;
  color: #222;
  font-weight: 600;
  padding: 14px 0;
  letter-spacing: 0;
}
.page-sidebar .menu > li > a:after {
  font-size: 14px;
  right: 18px;
}
.page-sidebar .widget {
  border: none;
}
@media (max-width: 991px) {
  .page-sidebar {
    display: none;
  }
}
.service-wrapper {
  border: 1px solid #e1e1e1;
  border-radius: 0.4rem;
}
@media (min-width: 1600px) {
  .service-wrapper .owl-item:not(:first-child):after {
    content: "";
    position: absolute;
    top: 50%;
    left: -1px;
    transform: translateY(-50%);
    width: 1px;
    height: 37px;
    background-color: #e1e1e1;
  }
}
@media (min-width: 992px) {
  .service-wrapper .icon-box-icon {
    margin: 0 1.5rem 0.4rem 0;
  }
}
.service-wrapper .icon-box-side {
  padding: 3.5rem 0 3rem;
}
.service-wrapper .icon-box-title {
  margin-bottom: 0.4rem;
}
.service-wrapper .d-icon-truck {
  font-size: 4.7rem;
}
.service-wrapper .d-icon-secure,
.service-wrapper .d-icon-service {
  font-size: 3.9rem;
}
.service-wrapper .d-icon-money {
  font-size: 3.2rem;
}
 
.product-countdown-container {
  font-size: 14px;
  background-color: #fe7c00;
  padding: 6px 15px 7px;
  border-radius: 2px;
  letter-spacing: 0;
  font-weight: 600;
  color: #fff;
}
.product-countdown-container label,
.product-countdown-container span {
  line-height: 1;
}
.product-countdown-container .countdown-row:after {
  display: none;
}
.btn-dark.btn-link:active,
.btn-dark.btn-link:focus,
.product-deals-wrapper .btn-dark.btn-link:hover {
  color: #fe7c00;
}
.banner-part > figure img {
  object-fit: cover;
}
.banner-part .banner-content {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.banner-part .content-left {
  padding-left: 3em;
}
.banner-part .content-right {
  justify-content: flex-end;
}
.banner-part .deal-image img {
  max-width: 37px;
}
.banner-part .banner-title {
  font-size: 2.4em;
}
.banner-part .banner-descri,
.banner-part .banner-subtitle {
  font-size: 1.8em;
  opacity: 0.6;
}
.banner-part .banner-descri {
  margin-right: 20px;
  line-height: 1.3;
}
.banner-part .btn {
  border-radius: 0.3rem;
  padding: 13px 28px;
}
@media (min-width: 1200px) {
  .banner-part > figure img {
    min-height: 94px;
  }
  .banner-part .btn {
    margin-right: 2rem;
  }
}
@media (min-width: 1600px) {
  .banner-part .banner-descri,
  .banner-part .btn {
    margin-right: 50px;
  }
  .banner-part .content-left {
    padding-left: 60px;
  }
}
@media (max-width: 1199px) {
  .banner-part .content-left,
  .banner-part .content-right {
    justify-content: center;
  }
  .banner-part > figure img {
    min-height: 200px;
  }
}
.category-icons .owl-carousel {
  overflow: hidden;
}
.category-icons .owl-carousel .owl-stage-outer {
  margin: 0 -30px;
  padding: 0 30px;
}
 
 
.category-icons .category.category-icon {
  padding: 3.2rem 0.2rem;
}
.category-icons .category.category-icon i {
  font-size: 55px;
}
.grid-type {
  display: grid;
  grid-template-columns: repeat(auto-fill, calc(16.667%));
  margin: -1rem;
}
@media (max-width: 1599px) {
  .grid-type {
    grid-template-columns: repeat(auto-fill, calc(33.333%));
  }
}
@media (max-width: 767px) {
  .grid-type {
    grid-template-columns: repeat(auto-fill, calc(100% / 2));
  }
}
.grid-type .product-wrap {
  grid-row-end: span 1;
  grid-column-end: span 1;
}
.grid-type .product-single-wrap {
  grid-column-end: span 2;
}
.tab-default .product-single-wrap img {
  min-height: 323px;
  object-fit: cover;
}
.tab-default .product-name {
  margin-bottom: 3px;
}
.tab-default .tab-pane {
  padding-top: 20px;
}
.tab-default .nav-link {
  font-size: 13px;
  letter-spacing: -0.025em;
  line-height: 2;
  background-color: #fff;
  color: #222;
  border-radius: 18px;
  padding: 4px 20px;
  border: 2px solid #e1e1e1;
  transition: border-color 0.2s, color 0.2s;
}
.tab-default .nav-item:not(:last-child) {
  margin-right: 1rem;
}
.tab-default .nav-item:hover .nav-link,
.tab-default .nav-tabs .nav-link.active {
  color: #fe7c00;
  border-color: #fe7c00;
}
.tab-default .banner-title {
  font-size: 28px;
}
.tab-default .banner-subtitle {
  font-size: 18px;
}
.tab-default .btn {
  padding: 13px 28px;
}
.tab-default .btn:not(:hover) {
  border-color: #8f8f8f;
}
.tab-default .tab-header {
  justify-content: space-between;
}
@media (max-width: 767px) {
  .tab-default .tab-header {
    justify-content: center;
  }
  .tab-default .tab-header > * {
    width: 100%;
  }
  .tab-default .tab-header .nav-item {
    margin: 0;
    padding: 0.5rem;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (max-width: 1199px) {
  .tab-default .tab-header {
    flex-direction: column;
    align-items: center;
  }
}
.tab-default .banner,
.tab-default .banner > figure,
.tab-default .banner > figure img {
  height: 100%;
}
.tab-1 .banner-content,
.tab-3 .banner-content {
  top: 10.9%;
  left: 8.2%;
}
.tab-2 .banner-content {
  top: 15.5%;
  left: 8.3%;
}
.banner-default .banner-title {
  font-size: 3em;
}
.banner-default .banner-subtitle {
  font-size: 2em;
}
.banner-default figure {
  border-radius: 6px;
  overflow: hidden;
}
.banner-default img {
  min-height: 250px;
  object-fit: cover;
}
.banner-part2 .banner-descri {
  font-size: 18px;
}
.banner-part2 .banner-descri > span:not(.text-decoration) {
  font-size: 30px;
}
.banner-part2 .banner-content {
  top: 29.9%;
  left: 7.9%;
}
.banner-part2 .text-decoration {
  text-decoration: line-through;
}
.banner-part3 .banner-content {
  left: 7.7%;
}
.brand-wrapper .image-wrap {
  padding-bottom: 1px;
}
.brand-wrapper figure {
  max-width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
}
.owl-shadow-carousel {
  overflow: hidden;
}
.recent-product figure {
  transition: box-shadow 0.3s;
  margin-bottom: 30px;
}
.recent-product figure:hover {
  box-shadow: 0px 20px 20px -16px rgba(0, 0, 0, 0.5);
}
.footer-middle .widget-newsletter {
  margin-bottom: 4.3rem;
}
.footer-middle .widget-title {
  text-transform: uppercase;
}
.footer-middle .widget-body li {
  margin-bottom: 12px;
  line-height: 1.2;
}
.widget-newsletter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 4.9rem;
  border-bottom: 1px solid #333;
}
.widget-newsletter .newsletter-info {
  max-width: 45rem;
  margin-top: 5px;
}
.widget-newsletter .input-wrapper {
  height: 4.9rem;
  margin-right: -10px;
}
.widget-newsletter .input-wrapper .btn {
  min-width: 13.1rem;
  display: flex;
  justify-content: center;
}
.widget-newsletter input.form-control {
  font-style: italic;
  font-family: poppins;
}
.widget-contact {
  font-family: poppins;
}
.widget-contact label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 400;
  color: #fff;
}
.widget-contact li {
  margin-bottom: 1.6rem;
}
.footer-bottom p {
  color: #777;
}
@media (max-width: 991px) {
  .footer .widget-contact ul {
    column-count: 2;
    column-gap: 20px;
  }
  .footer .widget-newsletter {
    display: block;
    margin-left: 0;
    text-align: center;
  }
  .footer .widget-newsletter > * {
    margin-left: auto;
    margin-right: auto;
  }
  .footer .newsletter-info {
    margin-bottom: 2rem;
  }
  .footer-middle .row > div:last-child .widget {
    margin-bottom: 3rem;
  }
}
@media (min-width: 576px) {
  .footer .widget:not(.widget-contact) ul {
    column-count:1;
    column-gap: 20px;
  }
  .footer .widget:not(.widget-contact) li {
    margin-bottom: 0.9rem;
  }
  .footer .widget:not(.widget-contact) .widget-body {
    padding-top: 0.3rem;
  }
}
@media (max-width: 575px) {
  .footer .widget-contact ul {
    column-count: 1;
  }
}
.mobile-menu .tab-pane {
  color: #fff;
}
.mobile-menu .tab-pane.active > ul {
  display: block;
}
.market3-shop .page-title {
  padding: 107px 0 3px;
}
.market3-product .header,
.market3-product .sticky-content.fixed:not(.sticky-footer) {
  background: #222;
}
.left-menu-fixed{
  position: fixed;
  height: 100vh;
  overflow-y: auto;

}
