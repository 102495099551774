/*! 
* @package IcoFont 
* @version 1.0.1 
* @author   
* @copyright 2024 
* @license - https://icofont.com/license/
*/

@font-face
{
font-family: "direction";
font-weight: normal;
font-style: "Regular";
src: url("direction/icofont.woff2") format("woff2"),
url("direction/icofont.woff") format("woff");
}

[class^="direction-"], [class*="direction-"]
{
  font-family: 'direction' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  line-height: 1;
/* Better Font Rendering =========== */
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.direction-arrow-down:before
{
  content: "\ea5b";
}

.direction-arrow-left:before
{
  content: "\ea5c";
}

.direction-arrow-right:before
{
  content: "\ea5d";
}

.direction-arrow-up:before
{
  content: "\ea5e";
}

.direction-block-down:before
{
  content: "\ea5f";
}

.direction-block-left:before
{
  content: "\ea60";
}

.direction-block-right:before
{
  content: "\ea61";
}

.direction-block-up:before
{
  content: "\ea62";
}

.direction-bubble-down:before
{
  content: "\ea63";
}

.direction-bubble-left:before
{
  content: "\ea64";
}

.direction-bubble-right:before
{
  content: "\ea65";
}

.direction-bubble-up:before
{
  content: "\ea66";
}

.direction-caret-down:before
{
  content: "\ea67";
}

.direction-caret-left:before
{
  content: "\ea68";
}

.direction-caret-right:before
{
  content: "\ea69";
}

.direction-caret-up:before
{
  content: "\ea6a";
}

.direction-circled-down:before
{
  content: "\ea6b";
}

.direction-circled-left:before
{
  content: "\ea6c";
}

.direction-circled-right:before
{
  content: "\ea6d";
}

.direction-circled-up:before
{
  content: "\ea6e";
}

.direction-collapse:before
{
  content: "\ea6f";
}

.direction-cursor-drag:before
{
  content: "\ea70";
}

.direction-curved-double-left:before
{
  content: "\ea71";
}

.direction-curved-double-right:before
{
  content: "\ea72";
}

.direction-curved-down:before
{
  content: "\ea73";
}

.direction-curved-left:before
{
  content: "\ea74";
}

.direction-curved-right:before
{
  content: "\ea75";
}

.direction-curved-up:before
{
  content: "\ea76";
}

.direction-dotted-down:before
{
  content: "\ea77";
}

.direction-dotted-left:before
{
  content: "\ea78";
}

.direction-dotted-right:before
{
  content: "\ea79";
}

.direction-dotted-up:before
{
  content: "\ea7a";
}

.direction-double-left:before
{
  content: "\ea7b";
}

.direction-double-right:before
{
  content: "\ea7c";
}

.direction-expand-alt:before
{
  content: "\ea7d";
}

.direction-hand-down:before
{
  content: "\ea7e";
}

.direction-hand-drag:before
{
  content: "\ea7f";
}

.direction-hand-drag1:before
{
  content: "\ea80";
}

.direction-hand-drag2:before
{
  content: "\ea81";
}

.direction-hand-drawn-alt-down:before
{
  content: "\ea82";
}

.direction-hand-drawn-alt-left:before
{
  content: "\ea83";
}

.direction-hand-drawn-alt-right:before
{
  content: "\ea84";
}

.direction-hand-drawn-alt-up:before
{
  content: "\ea85";
}

.direction-hand-drawn-down:before
{
  content: "\ea86";
}

.direction-hand-drawn-left:before
{
  content: "\ea87";
}

.direction-hand-drawn-right:before
{
  content: "\ea88";
}

.direction-hand-drawn-up:before
{
  content: "\ea89";
}

.direction-hand-grippers:before
{
  content: "\ea8a";
}

.direction-hand-left:before
{
  content: "\ea8b";
}

.direction-hand-right:before
{
  content: "\ea8c";
}

.direction-hand-up:before
{
  content: "\ea8d";
}

.direction-line-block-down:before
{
  content: "\ea8e";
}

.direction-line-block-left:before
{
  content: "\ea8f";
}

.direction-line-block-right:before
{
  content: "\ea90";
}

.direction-line-block-up:before
{
  content: "\ea91";
}

.direction-long-arrow-down:before
{
  content: "\ea92";
}

.direction-long-arrow-left:before
{
  content: "\ea93";
}

.direction-long-arrow-right:before
{
  content: "\ea94";
}

.direction-long-arrow-up:before
{
  content: "\ea95";
}

.direction-rounded-collapse:before
{
  content: "\ea96";
}

.direction-rounded-double-left:before
{
  content: "\ea97";
}

.direction-rounded-double-right:before
{
  content: "\ea98";
}

.direction-rounded-down:before
{
  content: "\ea99";
}

.direction-rounded-expand:before
{
  content: "\ea9a";
}

.direction-rounded-left-down:before
{
  content: "\ea9b";
}

.direction-rounded-left-up:before
{
  content: "\ea9c";
}

.direction-rounded-left:before
{
  content: "\ea9d";
}

.direction-rounded-right-down:before
{
  content: "\ea9e";
}

.direction-rounded-right-up:before
{
  content: "\ea9f";
}

.direction-rounded-right:before
{
  content: "\eaa0";
}

.direction-rounded-up:before
{
  content: "\eaa1";
}

.direction-scroll-bubble-down:before
{
  content: "\eaa2";
}

.direction-scroll-bubble-left:before
{
  content: "\eaa3";
}

.direction-scroll-bubble-right:before
{
  content: "\eaa4";
}

.direction-scroll-bubble-up:before
{
  content: "\eaa5";
}

.direction-scroll-double-down:before
{
  content: "\eaa6";
}

.direction-scroll-double-left:before
{
  content: "\eaa7";
}

.direction-scroll-double-right:before
{
  content: "\eaa8";
}

.direction-scroll-double-up:before
{
  content: "\eaa9";
}

.direction-scroll-down:before
{
  content: "\eaaa";
}

.direction-scroll-left:before
{
  content: "\eaab";
}

.direction-scroll-long-down:before
{
  content: "\eaac";
}

.direction-scroll-long-left:before
{
  content: "\eaad";
}

.direction-scroll-long-right:before
{
  content: "\eaae";
}

.direction-scroll-long-up:before
{
  content: "\eaaf";
}

.direction-scroll-right:before
{
  content: "\eab0";
}

.direction-scroll-up:before
{
  content: "\eab1";
}

.direction-simple-down:before
{
  content: "\eab2";
}

.direction-simple-left-down:before
{
  content: "\eab3";
}

.direction-simple-left-up:before
{
  content: "\eab4";
}

.direction-simple-left:before
{
  content: "\eab5";
}

.direction-simple-right-down:before
{
  content: "\eab6";
}

.direction-simple-right-up:before
{
  content: "\eab7";
}

.direction-simple-right:before
{
  content: "\eab8";
}

.direction-simple-up:before
{
  content: "\eab9";
}

.direction-square-down:before
{
  content: "\eaba";
}

.direction-square-left:before
{
  content: "\eabb";
}

.direction-square-right:before
{
  content: "\eabc";
}

.direction-square-up:before
{
  content: "\eabd";
}

.direction-stylish-down:before
{
  content: "\eabe";
}

.direction-stylish-left:before
{
  content: "\eabf";
}

.direction-stylish-right:before
{
  content: "\eac0";
}

.direction-stylish-up:before
{
  content: "\eac1";
}

.direction-swoosh-down:before
{
  content: "\eac2";
}

.direction-swoosh-left:before
{
  content: "\eac3";
}

.direction-swoosh-right:before
{
  content: "\eac4";
}

.direction-swoosh-up:before
{
  content: "\eac5";
}

.direction-thin-double-left:before
{
  content: "\eac6";
}

.direction-thin-double-right:before
{
  content: "\eac7";
}

.direction-thin-down:before
{
  content: "\eac8";
}

.direction-thin-left:before
{
  content: "\eac9";
}

.direction-thin-right:before
{
  content: "\eaca";
}

.direction-thin-up:before
{
  content: "\eacb";
}

[class^="icofont-"].direction-duotone,
	[class*=" icofont-"].direction-duotone
{
  position: relative;
}

[class^="icofont-"].direction-duotone:before,
	[class*=" icofont-"].direction-duotone:before
{
  position: absolute;
  left: 0;
  top: 0;
}

[class^="icofont-"].direction-duotone:after,
	[class*=" icofont-"].direction-duotone:after
{
  opacity: 0.4;
}

.direction-xs
{
  font-size: .5em;
}

.direction-sm
{
  font-size: .75em;
}

.direction-md
{
  font-size: 1.25em;
}

.direction-lg
{
  font-size: 1.5em;
}

.direction-1x
{
  font-size: 1em;
}

.direction-2x
{
  font-size: 2em;
}

.direction-3x
{
  font-size: 3em;
}

.direction-4x
{
  font-size: 4em;
}

.direction-5x
{
  font-size: 5em;
}

.direction-6x
{
  font-size: 6em;
}

.direction-7x
{
  font-size: 7em;
}

.direction-8x
{
  font-size: 8em;
}

.direction-9x
{
  font-size: 9em;
}

.direction-10x
{
  font-size: 10em;
}

.direction-fw
{
  text-align: center;
  width: 1.25em;
}

.direction-ul
{
  list-style-type: none;
  padding-left: 0;
  margin-left: 0;
}

.direction-ul > li
{
  position: relative;
  line-height: 2em;
}

.direction-ul > li .direction
{
  display: inline-block;
  vertical-align: middle;
}

.direction-border
{
  border: solid 0.08em #f1f1f1;
  border-radius: .1em;
  padding: .2em .25em .15em;
}

.direction-pull-left
{
  float: left;
}

.direction-pull-right
{
  float: right;
}

.direction.direction-pull-left
{
  margin-right: .3em;
}

.direction.direction-pull-right
{
  margin-left: .3em;
}

.direction-spin
{
  -webkit-animation: icofont-spin 2s infinite linear;
  animation: icofont-spin 2s infinite linear;
  display: inline-block;
}

.direction-pulse
{
  -webkit-animation: icofont-spin 1s infinite steps(8);
  animation: icofont-spin 1s infinite steps(8);
  display: inline-block;
}

@-webkit-keyframes icofont-spin
{
  0%
  {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100%
  {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes icofont-spin
{
  0%
  {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100%
  {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.direction-rotate-90
{
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.direction-rotate-180
{
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.direction-rotate-270
{
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.direction-flip-horizontal
{
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.direction-flip-vertical
{
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}

.direction-flip-horizontal.direction-flip-vertical
{
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

:root .direction-rotate-90,
	:root .direction-rotate-180,
	:root .direction-rotate-270,
	:root .direction-flip-horizontal,
	:root .direction-flip-vertical
{
  -webkit-filter: none;
  filter: none;
  display: inline-block;
}

.direction-inverse
{
  color: #fff;
}
	