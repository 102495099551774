.user-profile-image{
    background-color:#9E9E9E ;
    height: 250px;
    width: 250px;
    border-radius: 150px;
}
.user-dashboard{
    background-color: var(--rm-bg);
    height: 140px;
    border-radius: 20px;
    margin:0px 0px 20px 0;
    font-size: 40px;
    color: #ffffff; 
}
 
.profile-data{
    margin-top: 50px;
    
}
.profile-data h5{
    margin: 0;
    
}
.profile-data p{
    margin: 0 0 10px 0;
    padding: 0;
    
}
  

.userdashboard-value{
    font-size: 25px;
    margin: 0 0 0 16px;
}
.user-dashboard-main span{
    font-size: 17px;
    display: block;
}
.adress-container{
    background-color:#f1f1f1;
    border-radius: 10px;
    padding: 10px 0 10px 10px ; 
    margin: 0 0 10px 0;
}
.adress-container a{
    list-style-type: none;
   color: black;
   padding: 5px 20px 5px 0;
   

}
.less{
    float: right;
    font-size: 18px;
   
}
.common-address h5{
    font-size:20px;
    margin: 0;
}
.common-address h2{
    font-size:20px;
    margin: 0;
}
.common-address p{
    font-size: 16px;
}
.profile-data p{
    font-size: 16px;
}
.add-shipping-addres{
    font-size: 21px;
}
 