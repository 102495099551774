.message {
	font-size: 18px; /* Adjust the font size as needed */
	font-weight: bold; /* You can also adjust the font weight */
	color: red; /* Change the color to your preference */
  }
  
.step-by {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding: 0.9rem 0
}

.step-by .title.title-step {
	margin: 0 3.4rem 0 1rem;
	font-size: 2rem;
	text-transform: uppercase;
	font-weight: 700;
	color: #666;
	letter-spacing: -0.5px;
	padding: 0.4rem 0
}

.step-by .title.title-step:not(:last-child):after {
	content: "";
	display: inline-block;
	position: absolute;
	right: -3rem;
	top: 52%;
	line-height: 0;
	vertical-align: middle;
	font-family: "Font Awesome 5 Free";
	font-weight: 700;
	font-size: 1.7rem;
	background-color: #fff;
	color: #ccc
}

.step-by .title.title-step.visited {
	color: #222
}

.step-by .title.title-step.visited~.active:before {
	color: #222
}


.step-by .title.title-step:first-child {
	margin-left: 0
}

.step-by .title.title-step:first-child:before {
	display: none
}

.step-by .title.title-step:last-child {
	margin-right: 0
}


.shop-table {
	text-align: left
}

.shop-table tbody {
	border-bottom: 1px solid #e1e1e1
}

.shop-table td {
	padding: 1.5rem 1rem 1.5rem 0;
	border-top: 1px solid #e1e1e1;
	font-size: 1.4rem
}

.shop-table th {
	padding: 0.3rem 0 0.3rem;
	font-size: 1.6rem;
	font-weight: 600;
	line-height: 1.875;
	text-transform: uppercase;
	color: #222
}

.shop-table img {
	display: block;
	max-width: 100px
}

.shop-table .product-thumbnail a {
	display: block;
	width: 100px
}

.shop-table .remove {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2.3rem;
	height: 2.3rem;
	font-size: 1.2rem;
	color: #222;
	border: 1px solid #ccc;
	border-radius: 50%
}

.shop-table .product-name {
	white-space: normal;
	color: #000
}

.shop-table td.product-remove {
	padding-right: 0;
	width: 23px
}

.shop-table .product-price {
	display: table-cell
}


.cart.main {
	border-top: 1px solid #e1e1e1
}

.cart .accordion {
	border-bottom: 1px solid #e1e1e1
}

.cart .card-header {
	font-size: 1.4rem
}

.cart .card-header>a {
	padding-left: 2px
}

.cart .card-body {
	margin-bottom: 2.4rem;
	padding: 0
}

.cart .form-control {
	font-family: poppins
}

.cart .remove {
	margin-right: 0;
	width: 2rem;
	text-align: center
}

.cart .summary {
	line-height: 2.15
}

.cart .summary .title {
	margin-bottom: 1rem;
	padding-bottom: 1.8rem;
	border-bottom: 1px solid #e1e1e1
}

.cart .btn-checkout {
	display: block;
	padding-top: 1.06em;
	padding-bottom: 1.06em;
	border-color: #333;
	background-color: #333
}

.cart .product-thumbnail figure {
	position: relative;
	display: inline-block
}

.cart .shop-table th {
	padding: 0.2rem 0 1.8rem;
	font-size: 1.3rem;
	font-weight: 700;
	color: #999;
	line-height: 1;
	letter-spacing: -0.025em
}

.cart .shop-table td {
	padding: 1.6rem 2rem 1.6rem 0
}

.cart .shop-table td:last-child {
	padding-right: 0
}

.cart .shop-table td:first-child {
	padding: 2rem 2rem 1.5rem 0
}

.cart .shop-table .quantity.form-control {
	font-weight: 700
}

.cart .product-remove {
	font-size: 12px;
	padding:5px 10px;
	align-items: center;
	justify-content: center;
	border: 1px solid #ccc;
	background-color: #fff;
	color: #222
}
.cart-qty{
	font-size: 14px;
}
.cart-qty-btn{
	border:solid 1px #bbbbbb;
	width: 25px;
	height: 25px;

	text-align: center;
	font-size: 14px;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	line-height: 25px;
	cursor: pointer;
}
.cart-qty-value{
	height: 25px;
	min-width: 10px;
	line-height: 25px;
	text-align: center;
	font-size: 14px;
	margin: 0 10px;
}
.cart-row{
	border-bottom:solid 1px #7d7d7d ;
	padding:  10px 0;
}
.cart-row .product-img img{
	 border: #cccccc solid 1px;
}
.cart p{
	margin: 0;
	font-size: 14px;
}
.cart .amount{
	font-size: 20px;
    font-weight: bold;
    margin: 0 10px 10px 0;
}
.cart .product-name{
	font-size: 18px;
    font-weight: bold;
    white-space: normal;
    color: #000000;
	line-height: 25px;
	margin: 0 0 10px 0;

}

.cart .shipping {
	margin-bottom: 0.3rem
}

.cart .btn {
	border-width: 1px
}

.cart .shipping-row-last .summary-subtitle {
	padding: 2.4rem 0 1.9rem
}

.cart .shipping-row-last li {
	padding-bottom: 0.3rem
}

.cart-table .product-name {
	font-weight: 600;
	color: #222;
	line-height: 1.86
}
.cart .seller-name{
	font-size: 15px;
}
.cart-table .product-price {
	width: 100px
}

.cart-table .product-quantity {
	width: 135px
}

.cart-table .product-subtotal {
	width: 85px;
	color: #222
}

.cart-table .input-group button,
.cart-table .input-group input {
	border-color: #eee
}

.cart-table .input-group .quantity-minus {
	border-radius: 0.3rem 0 0 0.3rem
}

.cart-table .input-group .quantity-plus {
	border-radius: 0 0.3rem 0.3rem 0
}

.cart-actions {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap
}

.cart-actions .btn-icon-left {
	padding: 1.06em 2.2em
}

.cart-actions .btn-icon-left i {
	margin-right: 0.8rem;
	font-size: 2rem
}

.cart-actions button {
	padding: 1.07em 2.23em
}

.cart-actions button.btn-disabled {
	border-color: #222 !important;
	background-color: transparent !important;
	border-width: 1px;
	color: #222 !important;
	opacity: 0.4;
	cursor: default
}

.cart-coupon-box .coupon-title {
	margin-bottom: 2.3rem;
	padding-top: 0.8rem;
	font-size: 1.8rem
}

#coupon_code {
	padding-left: 1.6rem;
	padding-right: 1.6rem;
	font-size: 1.3rem;
	border: none;
	border-bottom: 1px solid #e1e1e1;
	transition: border-color 0.3s;
	border-radius: 0
}

.checkout {
	line-height: 1.54
}

.checkout.main {
	border-top: 1px solid #e1e1e1
}

.checkout .alert.card-header {
	padding-top: 1.2rem;
	padding-bottom: 1.3rem;
	background-color: #fff;
	border: 1px dashed #cacbcc;
	text-transform: none
}

.checkout .alert.card-header>a {
	display: inline-block;
	padding: 0
}

.checkout .alert.card-header>a:after,
.checkout .alert.card-header>a:before {
	content: none
}

.checkout .alert-icon>i {
	margin-right: 0.8rem
}

.checkout .alert-body {
	transition: display 0.3s
}

.checkout .alert-body p {
	font-size: 1.3rem
}

.checkout .alert-body.expanding {
	display: none
}

.checkout .alert-body.collapsing {
	display: block
}

.checkout .alert-body .form-control {
	margin: 0
}

.checkout .checkbox {
	margin: 0.6rem 0 1.6rem 0.2rem
}

.checkout .link-group .btn {
	padding: 1em 2.5em
}

.checkout .link-group span {
	margin: 0 1.1rem;
	font-size: 1.4rem
}

.checkout .lost-link {
	font-size: 1.3rem;
	color: #000
}

.checkout .social-link {
	border-radius: 0.3rem;
	color: #fff
}

.checkout .social-link.social-google {
	background-color: #dd4b39;
	border-color: #dd4b39
}

.checkout .social-link.social-facebook {
	background-color: #3b5998;
	border-color: #3b5998
}

.checkout .social-link.social-twitter {
	background-color: #1da1f2;
	border-color: #1da1f2
}

.checkout .check-coupon-box .form-control {
	max-width: 42.5rem
}

.checkout .check-coupon-box .btn {
	padding: 1.04em 2.05em;
	border-width: 1px
}

.checkout label {
	display: block;
	padding-left: 0.2rem;
	margin-bottom: 1rem;
	font-size: 1.4rem;
	line-height: 0.9;
	
}
.radio-set{
	width: 50px;
	height: 50px;
}
.checkout .form .title.title-simple {
	margin-top: 0.3rem;
	font-size: 15px;
}

.checkout .select-box select {
	max-width: 100%;
	width: 100%
}
.mrp-price{
	text-decoration: line-through;
}
.checkout .form-control {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	font-size: 1.3rem;
	font-family: poppins;
	transition: background-color 0.3s, border-color 0.3s;
	color: #252525;
	min-height: 4.1rem;
	border-radius: 0.3rem;
	box-shadow: rgba(0, 0, 0, 0.15) 1px 1px 1.6px;
}

.checkout .form-control:not(:focus) {
	background-color: #fff
}

.checkout .form-control:not(select):focus {
	border-color: #000
}

.checkout textarea.form-control {
	padding-top: 1.5rem
}

.checkout .form-checkbox {
	position: relative;
}
.custom-checkbox {
	position: absolute;
	opacity: 0;
	border: #000 solid 5px;
	box-shadow: inset 0 0 10px #f8a100;
  }
  .form-checkbox .custom-checkbox + label {

  }
  .cart-seller h6{
	line-height: 1.5;
	margin:0 0 0 10px;
	font-size: 18px;
  }
  .custom-checkbox + label {
	display: block;
	position: relative;
	cursor: pointer;
	line-height: 1;
  }
  .custom-checkbox + label:before {
	font-family: "IcoFont";
	content: "\f00e";
	transform: translateY(-50%);
    left: 0;
	font-size: 19px;
	width: 25px;
	height: 25px;
	color: #ffffff ;
	opacity: 0;
  }
  @media (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.custom-checkbox + label:before {
	  line-height: 14px;
	}
  }
  .product-description{
	font-size: 14px;
  }
  .product-prict{
	font-size: 14px;
  }
  .custom-checkbox:checked + label:before {
	font-family: "IcoFont";
	content: "\f00e";
	opacity: 1;	
	color: #129e25;
	font-weight: 600;
	text-align: center;
  }
  .shipping-details .custom-checkbox:checked + label:before {
	position: absolute;
    top: 5px;
  }

.termsandconditions{
	font-size: 14px;
	margin:0 0 0 25px;
}
.radio-set{

}
.shippingaddress-checkbox{
	position: absolute;
}
.checkbox-set{
	position: absolute;
    border: #000000 solid 1px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
.checkout .form-control-label {
	margin: 0;
}

.checkout .product-name {
	font-size: 1.4rem;
	letter-spacing: 0;
	white-space: normal
}

.checkout .btn-order {
	width: 100%;
	transition: opacity 0.3s
}

.checkout .btn-order:hover {
	opacity: 0.9
}

.checkout .card {
	background: transparent
}

.checkout .card-header {
	font-size: 1.4rem;
	line-height: 3rem;
	text-transform: capitalize;
	font-weight: 300
}

.checkout .card-header a {
	padding: 0 0 0 2.5rem
}

.checkout .card-header a:after,
.checkout .card-header a:before {
	position: absolute;
	content: "";
	display: inline-block;
	border-radius: 50%;
	top: 50%;
	transform: translateY(-50%)
}

.checkout .card-header a:before {
	left: 0;
	width: 1.8rem;
	height: 1.8rem;
	background-color: #fff;
	border: 1px solid #cdcdcd
}

.checkout .card-header a.collapse:after {
	width: 0.8rem;
	height: 0.8rem;
	left: 0.5rem;
	background: #666
}

.checkout .card-body {
	padding: 0.2rem 0 1.1rem 2.9rem;
	line-height: 1.4
}

.checkout .payment.accordion {
	border-bottom: 1px solid #e1e1e1;
	padding-bottom: 2rem
}

.checkout .payment.accordion .summary-subtitle {
	font-size: 1.5rem
}

.checkout .checkout-info {
	padding: 1.5rem 0;
	margin: 0;
	font-size: 1.3rem;
	font-weight: 400;
	letter-spacing: 0.01em;
	font-family: poppins
}

.checkout .product-quantity {
	margin-left: 0.4rem
}

.checkout .title.title-simple {
	font-weight: 700
}

.checkout .summary .form-control-label {
	 
}

.checkout .summary .form-control-label:before {
	top: 0;
	transform: none
}

.checkout .summary .form-control-label a {
	color: #000
}

.checkout .summary .form-control-label a:hover {
	color: #26c
}

.summary .title {
	margin-bottom: 1.9rem;
	padding-bottom: 2.2rem;
	border-bottom: 1px solid #e1e1e1
}

.order-table {
	color: #222
}

.order-table th {
	padding: 0 0 0.7rem 0;
	font-size: 1.6rem;
	font-weight: 600
}

.order-table td {
	padding-bottom: 0.6rem;
	line-height: 2.2
}

.order-table .order-total td {
	border-bottom: none;
	padding: 1.2rem 0 0
}

.order-table .shipping-row label {
	margin-bottom: 1.5rem
}

.order-table .shipping-row-last {
	border-bottom: 1px solid #e1e1e1
}

.order-table .shipping-row-last label {
	margin-bottom: 2.1rem;
	color: #666
}

.order-table .summary-subtotal {
	border-top: 1px solid #e1e1e1
}

.order-table .summary-subtotal td:first-child {
	padding: 0.4rem 0 0.3rem
}

.order-table .summary-subtotal-price {
	padding-top: 1rem
}

.order-table tbody>tr:nth-child(2) td {
	padding-bottom: 1.6rem
}

.sumnary-shipping ul {
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: left
}

.sumnary-shipping .summary-subtitle {
	padding-bottom: 2.2rem;
	text-align: left
}

.summary-total {
	border-bottom: 1px solid #e1e1e1
}

.summary-total .summary-subtitle {
	padding: 2.3rem 0 2.1rem
}


.mt-0 {
	margin-top: 0rem !important
}

.mb-0 {
	margin-bottom: 0rem !important
}

.pt-0 {
	padding-top: 0rem !important
}

.pb-0 {
	padding-bottom: 0rem !important
}

.mt-1 {
	margin-top: 0.5rem !important
    
}

.mb-1 {
	margin-bottom: 0.5rem !important
}

.pt-1 {
	padding-top: 0.5rem !important
}

.pb-1 {
	padding-bottom: 0.5rem !important
}

.mt-2 {
	margin-top: 1rem !important
}

.mb-2 {
	margin-bottom: 1rem !important
}

.pt-2 {
	padding-top: 1rem !important
}

.pb-2 {
	padding-bottom: 1rem !important
}

.mt-3 {
	margin-top: 1.5rem !important
}

.mb-3 {
	margin-bottom: 1.5rem !important
}

.pt-3 {
	padding-top: 1.5rem !important
}

.pb-3 {
	padding-bottom: 1.5rem !important
}

.mt-4 {
	margin-top: 2rem !important
}

.mb-4 {
	margin-bottom: 2rem !important
}

.pt-4 {
	padding-top: 2rem !important
}

.pb-4 {
	padding-bottom: 2rem !important
}

.mt-5 {
	margin-top: 2.5rem !important
}

.mb-5 {
	margin-bottom: 2.5rem !important
}

.pt-5 {
	padding-top: 2.5rem !important
}

.pb-5 {
	padding-bottom: 2.5rem !important
}

.mt-6 {
	margin-top: 3rem !important
}

.mb-6 {
	margin-bottom: 3rem !important
}

.pt-6 {
	padding-top: 3rem !important
}

.pb-6 {
	padding-bottom: 3rem !important
}

.mt-7 {
	margin-top: 3.5rem !important
}

.mb-7 {
	margin-bottom: 3.5rem !important
}

.pt-7 {
	padding-top: 3.5rem !important
}

.pb-7 {
	padding-bottom: 3.5rem !important
}

.mt-8 {
	margin-top: 4rem !important
}

.mb-8 {
	margin-bottom: 4rem !important
}

.pt-8 {
	padding-top: 4rem !important
}

.pb-8 {
	padding-bottom: 4rem !important
}

.mt-9 {
	margin-top: 4.5rem !important
}

.mb-9 {
	margin-bottom: 4.5rem !important
}

.pt-9 {
	padding-top: 4.5rem !important
}

.pb-9 {
	padding-bottom: 4.5rem !important
}

.mt-10 {
	margin-top: 5rem !important
}

.mb-10 {
	margin-bottom: 5rem !important
}

.pt-10 {
	padding-top: 5rem !important
}

.pb-10 {
	padding-bottom: 5rem !important
}

.ml-0 {
	margin-left: 0rem !important
}

.mr-0 {
	margin-right: 0rem !important
}

.pl-0 {
	padding-left: 0rem !important
}

.pr-0 {
	padding-right: 0rem !important
}

.ml-1 {
	margin-left: 0.5rem !important
}

.mr-1 {
	margin-right: 0.5rem !important
}

.pl-1 {
	padding-left: 0.5rem !important
}

.pr-1 {
	padding-right: 0.5rem !important
}

.ml-2 {
	margin-left: 1rem !important
}

.mr-2 {
	margin-right: 1rem !important
}

.pl-2 {
	padding-left: 1rem !important
}

.pr-2 {
	padding-right: 1rem !important
}

.ml-3 {
	margin-left: 1.5rem !important
}

.mr-3 {
	margin-right: 1.5rem !important
}

.pl-3 {
	padding-left: 1.5rem !important
}

.pr-3 {
	padding-right: 1.5rem !important
}

.ml-4 {
	margin-left: 2rem !important
}

.mr-4 {
	margin-right: 2rem !important
}

.pl-4 {
	padding-left: 2rem !important
}

.pr-4 {
	padding-right: 2rem !important
}

.ml-5 {
	margin-left: 2.5rem !important
}

.mr-5 {
	margin-right: 2.5rem !important
}

.pl-5 {
	padding-left: 2.5rem !important
}

.pr-5 {
	padding-right: 2.5rem !important
}

.ml-6 {
	margin-left: 3rem !important
}

.mr-6 {
	margin-right: 3rem !important
}

.pl-6 {
	padding-left: 3rem !important
}

.pr-6 {
	padding-right: 3rem !important
}

.ml-7 {
	margin-left: 3.5rem !important
}

.mr-7 {
	margin-right: 3.5rem !important
}

.pl-7 {
	padding-left: 3.5rem !important
}

.pr-7 {
	padding-right: 3.5rem !important
}

.ml-8 {
	margin-left: 4rem !important
}

.mr-8 {
	margin-right: 4rem !important
}

.pl-8 {
	padding-left: 4rem !important
}

.pr-8 {
	padding-right: 4rem !important
}

.ml-9 {
	margin-left: 4.5rem !important
}

.mr-9 {
	margin-right: 4.5rem !important
}

.pl-9 {
	padding-left: 4.5rem !important
}

.pr-9 {
	padding-right: 4.5rem !important
}

.ml-10 {
	margin-left: 5rem !important
}

.mr-10 {
	margin-right: 5rem !important
}

.pl-10 {
	padding-left: 5rem !important
}

.pr-10 {
	padding-right: 5rem !important
}

.p-0 {
	padding: 0 !important
}

.m-0 {
	margin: 0 !important
}

.ml-auto {
	margin-left: auto !important
}

.mr-auto {
	margin-right: auto !important
}

.mx-auto {
	margin-left: auto !important;
	margin-right: auto !important
}

summary {
	padding: 2.4rem 3rem 3rem;
	border: 1px solid #e1e1e1;
	border-radius: 2px
}

.summary td:last-child,
.summary th:last-child {
	text-align: right
}

.summary th {
	text-align: left
}

.summary .form-control {
	width: 100%;
	max-width: 100%
}

.summary-title {
	font-size: 1.8rem;
	font-weight: 700;
	letter-spacing: -0.01em;
	color: #222;
	text-transform: uppercase;
	padding-bottom: 1.9rem;
	margin-bottom: 0;
	border-bottom: 1px solid #e1e1e1;
	padding: 9px 11px;
	background: #cccccc;
}

.summary-subtotal {
	border-bottom: 1px solid #e1e1e1
}

.summary-subtitle {
	font-size: 1.6rem;
	font-weight: 600;
	letter-spacing: -0.01em;
	color: #222;
	margin-bottom: 0;
	padding: 2rem 0
}

.summary-subtotal-price {
	font-size: 1.4rem;
	font-weight: 600;
	color: #222;
	margin-bottom: 0;
	font-family: poppins
}

.summary-total-price {
	font-size: 2rem;
	letter-spacing: -0.025em;
	font-weight: 700;
	color: #222;
	text-align: right;



	margin-bottom: 0;
	font-family: poppins
}

.total .summary-subtitle {
	padding: 2.4rem 0 2.3rem
}

@media (min-width:992px) {
	.col-lg-1 {
		max-width: 8.3333%;
		flex: 0 0 8.3333%
	}

	.col-lg-2 {
		max-width: 16.6667%;
		flex: 0 0 16.6667%
	}

	.col-lg-3 {
		max-width: 25%;
		flex: 0 0 25%
	}

	.col-lg-4 {
		max-width: 33.3333%;
		flex: 0 0 33.3333%
	}

	.col-lg-5 {
		max-width: 41.6667%;
		flex: 0 0 41.6667%
	}

	.col-lg-6 {
		max-width: 50%;
		flex: 0 0 50%
	}

	.col-lg-7 {
		max-width: 58.3333%;
		flex: 0 0 58.3333%
	}

	.col-lg-8 {
		max-width: 66.6667%;
		flex: 0 0 66.6667%
	}

	.col-lg-9 {
		max-width: 75%;
		flex: 0 0 75%
	}

	.col-lg-10 {
		max-width: 83.3333%;
		flex: 0 0 83.3333%
	}

	.col-lg-11 {
		max-width: 91.6667%;
		flex: 0 0 91.6667%
	}

	.col-lg-12 {
		max-width: 100%;
		flex: 0 0 100%
	}
}

@media (min-width:1200px) {
	.col-xl-1 {
		max-width: 8.3333%;
		flex: 0 0 8.3333%
	}

	.col-xl-2 {
		max-width: 16.6667%;
		flex: 0 0 16.6667%
	}

	.col-xl-3 {
		max-width: 25%;
		flex: 0 0 25%
	}

	.col-xl-4 {
		max-width: 33.3333%;
		flex: 0 0 33.3333%
	}

	.col-xl-5 {
		max-width: 41.6667%;
		flex: 0 0 41.6667%
	}

	.col-xl-6 {
		max-width: 50%;
		flex: 0 0 50%
	}

	.col-xl-7 {
		max-width: 58.3333%;
		flex: 0 0 58.3333%
	}

	.col-xl-8 {
		max-width: 66.6667%;
		flex: 0 0 66.6667%
	}

	.col-xl-9 {
		max-width: 75%;
		flex: 0 0 75%
	}

	.col-xl-10 {
		max-width: 83.3333%;
		flex: 0 0 83.3333%
	}

	.col-xl-11 {
		max-width: 91.6667%;
		flex: 0 0 91.6667%
	}

	.col-xl-12 {
		max-width: 100%;
		flex: 0 0 100%
	}
}

@media (min-width:1600px) {
	.col-xxl-10 {
		flex: 0 0 83.3333%;
		max-width: 83.3333%
	}

	.col-xxl-3 {
		flex: 0 0 25%;
		max-width: 25%
	}

	.col-xxl-2 {
		flex: 0 0 16.6666%;
		max-width: 16.6666%
	}
}

@media (min-width:1200px) {
	.col-xl-5col {
		position: relative;
		flex: 0 0 20%;
		max-width: 20%;
		padding-right: 10px;
		padding-left: 10px
	}

	.col-xl-5col2 {
		position: relative;
		flex: 0 0 40%;
		max-width: 40%;
		padding-right: 10px;
		padding-left: 10px
	}

	.col-xl-5col4 {
		position: relative;
		flex: 0 0 80%;
		max-width: 80%;
		padding-right: 10px;
		padding-left: 10px
	}
}

.order-first {
	order: -1
}

.order-last {
	order: 9
}

@media (min-width:576px) {
	.order-sm-auto {
		order: 0
	}

	.order-sm-first {
		order: -1
	}

	.order-sm-last {
		order: 9
	}
}

@media (min-width:768px) {
	.order-md-auto {
		order: 0
	}

	.order-md-first {
		order: -1
	}

	.order-md-last {
		order: 9
	}
}

@media (min-width:992px) {
	.order-lg-auto {
		order: 0
	}

	.order-lg-first {
		order: -1
	}

	.order-lg-last {
		order: 9
	}
}

@media (min-width:1200px) {
	.order-xl-auto {
		order: 0
	}

	.order-xl-first {
		order: -1
	}

	.order-xl-last {
		order: 9
	}
}
.sticky-sidebar mt-1{
    border-bottom: 0px rgb(102, 102, 102);
    width: 480px;
}

.body {
    font-family: poppins;
    font-size: 1.4rem;
    color: #666;
  }

  .card-header {
	text-transform: capitalize;
	font-size: 1.8rem;
	font-weight: 600;
	line-height: 1;
	color: #222
}
.font-primary {
	font-family: poppins !important
}

.font-secondary {
	font-family: poppins !important
}

.font-tertiary {
	font-family: "Open Sans", sans-serif !important
}

.font-weight-bolder {
	font-weight: 800 !important
}

.font-weight-bold {
	font-weight: 700 !important
}

.font-weight-semi-bold {
	font-weight: 600 !important
}

.font-weight-normal {
	font-weight: 400 !important
}

.font-weight-light {
	font-weight: 300 !important
}

.text-uppercase {
	text-transform: uppercase !important
}

.text-capitalize {
	text-transform: capitalize !important
}

.text-normal {
	text-transform: none !important
}

.font-italic {
	font-style: italic !important
}

.font-normal {
	font-stretch: normal !important
}

.text-left {
	text-align: left !important
}

.text-center {
	text-align: center !important
}

.text-right {
	text-align: right !important
}

.text-white {
	color: #fff !important
}

.text-light {
	color: #ccc !important
}

.text-grey {
	color: #999 !important
}

.text-body {
	color: #666 !important
}

.text-dark {
	color: #222 !important
}

.text-black {
	color: #000 !important
}

.text-primary {
	color: #26c !important
}

.text-secondary {
	color: #d26e4b !important
}

.ls-md {
	letter-spacing: -0.03em !important
}

.ls-s {
	letter-spacing: -0.01em !important
}

.ls-m {
	letter-spacing: -0.025em !important
}

.ls-l {
	letter-spacing: -0.05em !important
}

.ls-normal {
	letter-spacing: 0 !important
}

.lh-2 {
	line-height: 2 !important
}

.lh-1 {
	line-height: 1 !important
}

.lh-0 {
	line-height: 0 !important
}

.bg-white {
	background-color: #fff !important
}

.bg-dark {
	background-color: #222 !important
}

.bg-grey {
	background-color: #999 !important
}

.bg-light {
	background-color: #ccc !important
}

.bg-black {
	background-color: #000 !important
}

.bg-primary {
	background-color: #26c !important
}

.bg-secondary {
	background-color: #d26e4b !important
}

.border-no {
	border: none !important
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	font-weight: 400;
	margin: 0 0 2rem
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: poppins;
	font-weight: 600;
	line-height: 1.4;
	color: #222
}

h1 {
	font-size: 4rem
}

h2 {
	font-size: 3.4rem
}

h3 {
	font-size: 3rem
}

h4 {
	font-size: 2.4rem
}

h5 {
	font-size: 1.8rem
}

h6 {
	font-size: 1.5rem
}

p {
	font-family: poppins;
	font-size: 1.4rem;
	line-height: 1.86
}

blockquote {
	margin: 0
}

.list {
	font-size: 1.4rem;
	line-height: 1.86;
	color: #666
}

.list-style-none {
	list-style: none
}

.list-type-number {
	list-style-type: decimal
}

.list-type-check li {
	position: relative;
	padding-left: 2.4rem
}

.list-type-check li:before {
	display: block;
	position: absolute;
	left: 3px;
	top: 2px;
	font-family: "riode";
	content: "";
	font-weight: 700;
	font-size: 1.2rem;
	color: #222
}

.list-circle li {
	padding-left: 1rem;
	margin-bottom: 1rem
}

.list-circle i {
	margin-right: 1.2rem;
	width: 4rem;
	height: 4rem;
	border: 1px solid #e1e1e1;
	border-radius: 50%;
	text-align: center;
	line-height: 3.8rem;
	font-size: 1.1rem
}

.list-arrow li {
	position: relative;
	transition: color 0.3s, padding 0.3s
}

.list-arrow li:before {
	content: "";
	display: block;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	opacity: 0;
	visibility: hidden;
	font-family: "riode";
	font-size: 1.4rem;
	transition: opacity 0.3s, visibility 0.3s
}

.list-arrow li.show,
.list-arrow li:hover {
	padding-left: 2.2rem;
	color: #26c
}

.list-arrow li.show:before,
.list-arrow li:hover:before {
	visibility: visible;
	opacity: 1
}

.text-bd-left {
	position: relative;
	padding-left: 2rem
}

.text-bd-left:before {
	content: "";
	display: block;
	position: absolute;
	left: 0;
	width: 2px;
	top: 5px;
	bottom: 5px;
	background-color: #dae1e5
}


@media (max-width:479px) {

	.order-table tbody .product-name,
	.order-table tbody .product-total {
		padding-bottom: 2rem;
		line-height: 1.2
	}

	.order-table tbody>tr:nth-child(2) td {
		padding-bottom: 2rem
	}
}

.order .title {
	font-size: 2rem
}

.order .order-message {
	max-width: 34rem;
	padding: 3rem;
	box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
	border-radius: 1rem
}

.order .order-message .icon-box-icon {
	margin-right: 3.2rem
}

.order .order-message svg {
	width: 5rem;
	height: 5rem;
	stroke: #26c
}

.order .order-message .icon-box-title {
	font-size: 1.8rem
}

.order .order-results {
	display: flex;
	flex-wrap: wrap;
	margin: 4.8rem 0 1.9rem
}

.order .overview-item {
	display: flex;
	position: relative;
	flex-direction: column;
	flex-wrap: wrap;
	margin-bottom: 3rem
}

.order .overview-item:not(:last-child) {
	margin-right: 3rem;
	padding-right: 3.1rem
}

.order .overview-item:not(:last-child):after {
	content: "";
	position: absolute;
	right: 0;
	top: 50%;
	display: block;
	transform: translateY(-50%);
	width: 1px;
	height: 42px;
	background: #e1e1e1
}

.order .overview-item span {
	text-transform: uppercase
}

.order .overview-item strong {
	color: #222;
	font-weight: 600;
	font-size: 2rem
}

.order .btn.btn-back {
	font-size: 1.3rem
}

.order .summary-subtotal:last-child {
	border-bottom: none
}

.order .address-info {
	border-bottom: 1px solid #e1e1e1
}

.order .address-info p {
	color: #666;
	font-family: poppins;
	line-height: 2.15
}

.order-details {
	border: 1px solid #e1e1e1;
	border-radius: 3px;
	padding: 0.4rem 3rem
}

.order-details-table .product-name {
	padding: 1.6rem 0 1.8rem;
	font-size: 1.4rem;
	font-weight: 400;
	line-height: 1.42;
	color: #000;
	white-space: inherit
}

.order-details-table .product-name i {
	font-size: 1rem
}

.order-details-table .product-name span {
	font-weight: 700;
	color: #222
}

.order-details-table th>.summary-subtitle {
	font-size: 1.8rem;
	font-weight: 600;
	text-align: left
}

.order-details-table .product-price {
	display: block
}

.order-details-table .product-price,
.order-details-table .summary-subtotal-price {
	text-align: right
}

.order-details-table .summary-subtotal-price {
	font-weight: 600;
	color: #666
}

.order-details-table .product-price {
	font-size: 1.4rem;
	color: #666;
	font-weight: 400;
	padding-top: 1.2rem
}

.order-details-table thead {
	border-bottom: 1px solid #e1e1e1
}

.order-details-table thead .summary-subtitle {
	font-size: 1.8rem
}

.order-details-table tbody tr:first-child td {
	padding-top: 3.2rem
}

.order-details-table tbody tr:first-child td.product-price {
	padding-top: 2.8rem
}

@media (max-width:767px) {
	.order .order-message {
		font-size: 2rem
	}

	.order .overview-item {
		flex-direction: row;
		width: 100%;
		align-items: center
	}

	.order .overview-item:not(:last-child):after {
		content: none
	}

	.order .overview-item span {
		margin-right: 2rem
	}
}

@media (max-width:575px) {
	.order .order-details {
		padding: 0 2rem
	}

	.order .order-message {
		font-size: 1.8rem
	}

	.order .overview-item {
		font-size: 1.6rem
	}
}

@media (max-width:479px) {
	.order .order-details {
		padding: 0 1.5rem
	}

	.order .order-message {
		font-size: 1.6rem
	}

	.order .overview-item {
		font-size: 1.4rem
	}

	.step-by .title.title-step {
		font-size: 14px;
		margin: 0 15px 0 0px;
		padding: 5px 0;
	}
	.step-by .title.title-step:not(:last-child):after{
		right:-12px;
	}

	.step-by .title.title-step:after {
		font-size: 1.4rem;
		top: 50%
	}
}

.container-fluid .product-single .product-details {
	padding-top: 4px;
  }
  .account {
	line-height: 2.15;
  }
  .account .title {
	font-size: 3rem;
	letter-spacing: -0.035em;
  }
  .account p {
	font-family: poppins;
	line-height: 2.15;
	letter-spacing: -0.017em;
  }
  .account p span {
	color: #333;
  }
  .account p:first-child {
	letter-spacing: -0.005em;
  }
  .account p .link-to-tab {
	text-decoration: underline;
  }
  .account #dashboard p {
	color: #777;
  }
  .account .btn.btn-dark {
	padding: 0.93em 1.97em;
  }
  .account label {
	display: block;
	padding-left: 0.2rem;
	margin-bottom: 1rem;
	font-size: 1.4rem;
	line-height: 1;
  }
  .account .form-control {
	font-size: 1.3rem;
	font-family: poppins;
	transition: background-color 0.3s, border-color 0.3s;
	color: #999;
	border-radius: 0.3rem;
  }
  .account .form-control:focus {
	border-color: #222;
  }
  .account .nav-tabs {
	width: auto;
	border-right: none;
  }
  .account .nav-item {
	border-bottom: 1px solid #eee;
	color: #222;
  }
  .account .nav-item a {
	display: block;
	padding: 1.7rem 0.8rem 1.6rem;
	margin: 0;
	font-weight: 600;
	letter-spacing: -0.015em;
	line-height: 1;
	text-transform: none;
  }
  .account .nav-item:hover .nav-link {
	color: #26c;
  }
  .account .nav-item .nav-link.active,
  .account .nav-item.show .nav-link {
	color: #26c;
  }
  .account .tab-pane {
	padding: 0.8rem 0 0;
  }
  .account thead {
	text-align: left;
  }
  .account thead th {
	padding: 1.5rem 0;
	background-color: #f4f4f4;
  }
  .account tbody td {
	padding: 1.5rem 1rem 1.5rem 0;
  }
  .account tbody tr:nth-child(2n) td {
	background-color: #f4f4f4;
  }
  .account td:first-child {
	padding-left: 1rem;
  }
  .account .order-total {
	width: 25.9rem;
	font-size: 1.4rem;
	font-weight: 400;
	color: #666;
  }
  .account fieldset {
	margin: 7rem 0 2rem;
	padding: 2rem 2rem 1rem;
	border: 1px solid #e1e1e1;
  }
  .account legend {
	color: #222;
  }
  .account .btn {
	padding: 1em 1.87em;
  }
  .account .order-table {
	border: 1px solid #eee;
  }
  .account .order-action .btn {
	padding: 0;
  }
  #downloads .btn,
  .card-address .btn {
	padding: 0;
  }
  .card-address .btn i {
	font-size: 1.4rem;
  }
  .order-number {
	width: 11.4rem;
	color: #000;
  }
  .order-number a {
	font-weight: bold;
  }

  .order-date {
	width: 24.8rem;
	color: #666;
  }
  .order-status {
	width: 14.7rem;
	font-size: 1.2rem;
	color: #222;
  }
  .order-status span {
	display: inline-block;
	padding: 0.1rem 0.5rem;
	background-color: #ccc;
	border-radius: 0.3rem;
	line-height: 1.9;
  }
  .card-address p {
	margin-bottom: 1rem;
  }
  @media (max-width: 767px) {
	.account .nav-tabs {
	  width: 100%;
	}
	.account .tab-content {
	  padding-left: 1.5rem;
	}
  }
  @media (max-width: 479px) {
	.account .order-action,
	.account thead th:last-child {
	  display: none;
	}
  }
  .section-buttons .row {
	justify-content: center;
	text-align: center;
  }
  .section-buttons.background-section {
	padding: 7.5rem 0 5.5rem;
  }
  .section-buttons .btn {
	margin-bottom: 2rem;
  }
  .btn-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
  }
  .btn-wrapper .btn {
	margin-left: 1rem;
	margin-right: 1rem;
  }
  .btn-wrapper .btn-block {
	max-width: 18rem;
  }
  @media (min-width: 992px) {
	.btn-wrapper .btn {
	  margin: 1.4rem 0;
	}
	.btn-wrapper .btn:not(:last-child) {
	  margin-right: 2rem;
	}
}
.tab-content {
	position: relative;
  }
  .tab-content > .tab-pane {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	height: 0 !important;
	opacity: 0;
	z-index: -1;
	transition: visibility 0.3s, opacity 0.3s;
  }
  .tab-content > .tab-pane:not(.active) {
	overflow: hidden;
	visibility: hidden;
  }
  .tab-content > .active {
	position: relative;
	height: auto !important;
	opacity: 1;
	z-index: auto;
  }
  .tab-pane {
	padding: 2.3rem 0;
	line-height: 1.72;
	color: #666;
  }
  .tab-pane p {
	line-height: 1.72;
  }
  .tab-nav-simple .nav-link {
	border-bottom: 2px solid transparent;
	background-color: transparent;
	color: #666;
  }
  .tab-nav-simple .nav-item .nav-link.active,
  .tab-nav-simple .nav-item.show .nav-link,
  .tab-nav-simple .nav-item:hover .nav-link {
	border-bottom-color: #26c;
  }
  .tab-nav-center .nav {
	justify-content: center;
  }
  .tab-nav-right .nav {
	justify-content: flex-end;
  }
  .tab-nav-boxed .nav-item {
	margin-right: 0;
  }
  .tab-nav-boxed .nav-link {
	padding-left: 2.4rem;
	padding-right: 2.4rem;
  }
  .tab-nav-round .nav-item:not(:last-child) {
	margin-right: 2px;
  }
  .tab-nav-round .nav-link {
	margin-bottom: 0;
	border-radius: 2.3rem;
	background-color: #f2f3f5;
  }
  .tab-nav-round .nav-tabs {
	border-bottom: 0;
  }
  .tab-boxed .tab-pane {
	padding-left: 2rem;
	padding-right: 2rem;
  }
  .tab-outline .tab-content {
	border: 1px solid #ebebeb;
  }
  .tab-outline .nav-tabs {
	border-bottom: 0;
  }
  .tab-outline .nav-link {
	padding-top: 1.4rem;
	padding-bottom: 1.5rem;
	border: 1px solid transparent;
	border-top-width: 2px;
  }
  .tab-outline .nav-item .nav-link.active,
  .tab-outline .nav-item.show .nav-link,
  .tab-outline .nav-item:hover .nav-link {
	border-color: #ebebeb;
	border-top-color: #26c;
  }
  .tab-outline2 .nav {
	position: relative;
	z-index: 1;
  }
  .tab-outline2 .tab-content {
	border: 1px solid #ebebeb;
  }
  .tab-outline2 .nav-tabs {
	border-bottom: 0;
  }
  .tab-outline2 .nav-link {
	padding: 1.5rem 2.4rem;
	border: 1px solid transparent;
  }
  .tab-outline2 .nav-item .nav-link.active,
  .tab-outline2 .nav-item.show .nav-link,
  .tab-outline2 .nav-item:hover .nav-link {
	border-color: #ebebeb;
	border-bottom-color: #fff;
  }
  .tab-vertical {
	display: flex;
  }
  .tab-vertical .nav-tabs {
	flex-flow: column nowrap;
	width: 27.8%;
	border: 0;
	border-right: 2px solid #ebebeb;
  }
  .tab-vertical .tab-content {
	flex: 1;
  }
  .tab-vertical .nav-item {
	margin-right: 0;
  }
  .tab-vertical .nav-link {
	position: relative;
	margin-right: -2px;
	padding-left: 2rem;
	width: calc(100% + 2px);
	border: 0;
	text-align: left;
  }
  .tab-vertical .nav-link:after {
	content: "";
	display: block;
	position: absolute;
	right: 0;
	width: 2px;
	height: calc(100% - 6px);
	top: 50%;
	transform: translateY(-50%);
	background-color: transparent;
	z-index: 1;
  }
  .tab-vertical .tab-pane {
	padding: 1.7rem 2rem;
  }
  .tab-vertical.tab-simple .nav-link {
	margin-right: -2px;
  }
  .tab-vertical.tab-simple .nav-link:after {
	content: "";
	display: block;
	position: absolute;
	right: 0;
	width: 2px;
	height: calc(100% - 6px);
	top: 50%;
	transform: translateY(-50%) scale(0);
	background-color: transparent;
	transition: transform 0.3s;
	z-index: 1;
  }
  .tab-vertical.tab-simple .nav-item .nav-link.active,
  .tab-vertical.tab-simple .nav-item.show .nav-link,
  .tab-vertical.tab-simple .nav-item:hover .nav-link {
	color: #26c;
  }
  .tab-vertical.tab-simple .nav-item .nav-link.active:after,
  .tab-vertical.tab-simple .nav-item.show .nav-link:after,
  .tab-vertical.tab-simple .nav-item:hover .nav-link:after {
	background-color: #26c;
	transform: translateY(-50%) scale(1);
  }
  @media (max-width: 767px) {
	.tab-vertical {
	  flex-flow: column nowrap;
	}
	.tab-vertical .nav-tabs,
	.tab-vertical .tab-content {
	  width: 100%;
	}
	.tab-vertical .nav-tabs {
	  border: 0;
	}
	.tab-vertical .tab-content {
	  padding-left: 0;
	}
	.tab-vertical.tab-simple .nav-link:after {
	  height: 2px;
	  width: 100%;
	  background-color: transparent;
	  top: calc(100% - 2px);
	  transform: scale(0);
	}
	.tab-vertical.tab-simple .nav-item .nav-link.active:after,
	.tab-vertical.tab-simple .nav-item.show .nav-link:after,
	.tab-vertical.tab-simple .nav-item:hover .nav-link:after {
	  transform: scale(1);
	}
  }
  .tab-inverse .tab-content {
	border-color: #fff;
  }
  .tab-inverse .nav-tabs {
	border: 0;
  }
  .tab-inverse .nav-link {
	margin: 0;
	width: 100%;
  }
  .tab-inverse .nav-item .nav-link.active,
  .tab-inverse .nav-item.show .nav-link,
  .tab-inverse .nav-item:hover .nav-link {
	background-color: #fff;
	border-color: #fff;
  }
  .tab-inverse.tab-simple .nav-link {
	padding-top: 1.4rem;
	border-top: 2px solid transparent;
  }
  .tab-inverse.tab-simple .nav-item .nav-link.active,
  .tab-inverse.tab-simple .nav-item.show .nav-link,
  .tab-inverse.tab-simple .nav-item:hover .nav-link {
	border-top-color: #26c;
  }
  .tab-nav-solid .nav-link {
	padding-bottom: 1.6rem;
	border: 0;
  }
  .tab-nav-solid .nav-item .nav-link.active,
  .tab-nav-solid .nav-item.show .nav-link,
  .tab-nav-solid .nav-item:hover .nav-link {
	color: #fff;
	background-color: #26c;
	border-color: #26c;
  }
  @media (max-width: 767px) {
	.nav-link {
	  font-size: 1.4rem;
	}
	.tab-nav-boxed .nav-link {
	  padding-left: 1rem;
	  padding-right: 1rem;
	}
  }
  @media (max-width: 479px) {
	.tab-nav-round .nav-link {
	  margin-bottom: 0.5rem;
	}
  }
  .row {
	display: flex;
	flex-wrap: wrap;

  }

  .banner-fixed intro-slide1{
	background-color: #dd0909;
	
  }
  .intro-slide1{
	background-color: #721616;
  }
  .intro-slide2{
	background-color: #2255bb;
  }
  .intro-slide3{
	background-color: #a02655;
  }
.owl-carousel .owl-item img {
  height: auto;
}
.owl-loaded .owl-stage:after {
  content: "";
}
.owl-carousel:not(.owl-loaded) {
  flex-wrap: nowrap;
  overflow: hidden;
}
.owl-carousel:not(.owl-loaded)[class*="cols-"]:not(.gutter-no) {
  margin-left: -10px !important;
  margin-right: -10px !important;
  width: auto;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 1.5rem;
}
.owl-theme.owl-shadow-carousel .owl-nav.disabled + .owl-dots {
  margin-top: 4rem;
}
.owl-theme .owl-nav .owl-next,
.owl-theme .owl-nav .owl-prev {
  margin: 0;
  transform: translateY(-50%);
  font-size: 20px;
  font-weight: 400;
  color: #26c;
}
.owl-theme .owl-nav .owl-next i:before,
.owl-theme .owl-nav .owl-prev i:before {
  margin: 0;
}
.owl-theme
  .owl-nav
  .owl-next:not(.disabled):active
  .owl-theme
  .owl-nav
  .owl-next:not(.disabled):focus,
.owl-theme
  .owl-nav
  .owl-next:not(.disabled):active
  .owl-theme
  .owl-nav
  .owl-prev:not(.disabled):focus,
.owl-theme .owl-nav .owl-next:not(.disabled):hover,
.owl-theme
  .owl-nav
  .owl-prev:not(.disabled):active
  .owl-theme
  .owl-nav
  .owl-next:not(.disabled):focus,
.owl-theme
  .owl-nav
  .owl-prev:not(.disabled):active
  .owl-theme
  .owl-nav
  .owl-prev:not(.disabled):focus,
.owl-theme .owl-nav .owl-prev:not(.disabled):hover {
  background-color: #26c;
  border-color: #26c;
  color: #fff;
}
.owl-theme .owl-nav .owl-next.disabled,
.owl-theme .owl-nav .owl-prev.disabled {
  color: #ccc;
}
.owl-theme .owl-nav .owl-prev {
  left: -40px;
}
.owl-theme .owl-nav .owl-next {
  right: -40px;
}
.owl-theme .owl-dots .owl-dot.active span {
  background-color: #26c;
  border-color: #26c;
}
.owl-theme .owl-dots .owl-dot span {
  margin: 5px;
}
@media (max-width: 1300px) {
  .owl-theme .owl-nav .owl-prev {
    left: 20px;
  }
  .owl-theme .owl-nav .owl-next {
    right: 20px;
  }
}
.owl-nav-full .owl-nav {
  opacity: 0;
  transition: opacity 0.4s;
}
.owl-nav-full .owl-nav .owl-next,
.owl-nav-full .owl-nav .owl-prev {
  margin: 0;
  top: 0;
  height: 100%;
  transform: none;
  border: 0;
  font-size: 3rem;
  font-weight: 400;
  color: #333;
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0.8);
  transition: 0.4s;
}
.owl-nav-full .owl-nav .owl-next:not(.disabled):hover,
.owl-nav-full .owl-nav .owl-prev:not(.disabled):hover {
  color: #26c;
  background-color: rgba(255, 255, 255, 0.8);
}
.owl-nav-full .owl-nav .owl-next.disabled,
.owl-nav-full .owl-nav .owl-prev.disabled {
  visibility: hidden;
  opacity: 0;
}
.owl-nav-full .owl-nav .owl-prev {
  left: 0;
  box-shadow: 4px 0px 7px -5px rgba(0, 0, 0, 0.2);
}
.owl-nav-full .owl-nav .owl-next {
  right: 0;
  box-shadow: -4px 0px 7px -5px rgba(0, 0, 0, 0.2);
}
.owl-nav-full:hover .owl-nav {
  opacity: 1;
}
.owl-nav-full:hover .owl-prev {
  left: 0;
}
.owl-nav-full:hover .owl-next {
  right: 0;
}
.owl-nav-arrow .owl-nav .owl-next,
.owl-nav-arrow .owl-nav .owl-prev {
  margin: 0;
  color: #666;
  font-weight: 400;
  width: 1em;
  height: 1em;
  font-size: 4.5rem;
  border: none;
}
.owl-nav-arrow .owl-nav .owl-next i,
.owl-nav-arrow .owl-nav .owl-prev i {
  display: none;
}
.owl-nav-arrow .owl-nav .owl-next:before,
.owl-nav-arrow .owl-nav .owl-prev:before {
  font-family: "riode";
}
.owl-nav-arrow .owl-nav .owl-next:not(.disabled):active,
.owl-nav-arrow .owl-nav .owl-next:not(.disabled):focus,
.owl-nav-arrow .owl-nav .owl-next:not(.disabled):hover,
.owl-nav-arrow .owl-nav .owl-prev:not(.disabled):active,
.owl-nav-arrow .owl-nav .owl-prev:not(.disabled):focus,
.owl-nav-arrow .owl-nav .owl-prev:not(.disabled):hover {
  background-color: transparent;
  color: #26c;
}
.owl-nav-arrow .owl-nav .owl-next.disabled,
.owl-nav-arrow .owl-nav .owl-prev.disabled {
  color: #aaa;
}
.owl-nav-arrow .owl-nav .owl-next {
  right: 5%;
}
.owl-nav-arrow .owl-nav .owl-next:before {
  content: "";
}
.owl-nav-arrow .owl-nav .owl-prev {
  left: 5%;
  margin-right: 20px;
}
.owl-nav-arrow .owl-nav .owl-prev:before {
  content: "";
}
.owl-nav-inner .owl-nav .owl-next,
.owl-nav-inner .owl-nav .owl-prev {
  opacity: 0;
  visibility: hidden;
}
.owl-nav-inner .owl-nav .owl-prev {
  left: 0;
}
.owl-nav-inner .owl-nav .owl-next {
  right: 0;
}
.owl-nav-inner:hover .owl-next,
.owl-nav-inner:hover .owl-prev {
  opacity: 1;
  visibility: visible;
}
.owl-nav-inner:hover .owl-prev {
  left: 2rem;
}
.owl-nav-inner:hover .owl-next {
  right: 2rem;
}
.owl-nav-top .owl-nav {
  position: absolute;
  display: flex;
  top: -5.3rem;
  right: -0.4rem;
  color: #ccc;
}
.owl-nav-top .owl-nav .owl-next:not(.disabled) .owl-prev:not(.disabled) {
  color: #777;
}
.owl-nav-top .owl-nav .owl-next,
.owl-nav-top .owl-nav .owl-prev {
  position: static;
  transform: translateY(-11%);
}
.owl-nav-top .owl-nav .owl-next {
  margin-right: 5px;
}
.owl-nav-top .owl-nav i {
  padding: 0.1rem 0.5rem;
  font-size: 1.8rem;
  font-weight: 700;
}
.owl-nav-top .owl-nav i:before {
  margin: 0;
}
.owl-nav-top.owl-nav-arrow .owl-nav .owl-next,
.owl-nav-top.owl-nav-arrow .owl-nav .owl-prev {
  font-size: 3.4rem;
}
.owl-nav-bottom .owl-nav {
  position: absolute;
  bottom: 1.7rem;
  right: -0.4rem;
  color: #999;
}
.owl-nav-bottom .owl-nav i {
  font-size: 2rem;
  font-weight: 700;
}
.owl-nav-bottom .owl-nav i:before {
  margin: 0;
}
.owl-nav-bottom .owl-prev {
  margin-right: 0.8rem;
}
.owl-dot-white .owl-dots .owl-dot span {
  background-color: #fff;
  border-color: #fff;
  opacity: 0.8;
}
.owl-dot-white .owl-dots .owl-dot.active span {
  background-color: #fff;
  border-color: #fff;
  opacity: 1;
}
.owl-dot-grey .owl-dots .owl-dot span {
  background-color: #efefef;
  border-color: #efefef;
}
.owl-dot-grey .owl-dots .owl-dot.active span {
  background-color: #999;
  border-color: #999;
}
.owl-dot-dark .owl-dots .owl-dot span {
  background-color: #a1a1a1;
  border-color: #a1a1a1;
}
.owl-dot-dark .owl-dots .owl-dot.active span {
  background-color: #333;
  border-color: #333;
}
.owl-dot-inner .owl-dots {
  position: absolute;
  bottom: 4.5rem;
  left: 50%;
  transform: translateX(-50%);
}
.brand-carousel .owl-stage {
  display: flex;
  align-items: center;
}
.brand-carousel .owl-item img {
  width: auto;
  margin: auto;
}
.brand-carousel:not(.loaded) {
  text-align: center;
  align-items: center;
}
.owl-carousel .slide-animate {
  transform: translate3d(0, 0, 0) scale(1);
  will-change: filter, transform, opacity;
  visibility: hidden;
  opacity: 0;
}
.owl-carousel .slide-animate.show-content {
  visibility: visible;
  opacity: 1;
}
.owl-full-height,
.owl-full-height .owl-item,
.owl-full-height .owl-stage,
.owl-full-height .owl-stage-outer,
.owl-same-height .owl-item > * {
  height: 100%;
}
.owl-same-height .owl-stage {
  display: flex;
}
.owl-full-height .owl-item img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.owl-shadow-carousel .owl-stage-outer {
  margin: -30px;
  padding: 30px;
}
.owl-nav-bg .owl-nav .owl-prev {
  left: 15.4%;
}
.owl-nav-bg .owl-nav .owl-next {
  right: 15.4%;
}
.owl-nav-bg .owl-nav [class*="owl-"] {
  width: 4.8rem;
  height: 4.8rem;
  border-width: 2px;
  font-size: 3.2rem;
  color: #666;
  border-color: #666;
}
.owl-nav-bg .owl-nav [class*="owl-"].disabled {
  border-color: #acabab;
}
.owl-nav-bg .owl-nav i {
  line-height: 1;
  margin-bottom: 2px;
}
.owl-nav-fade .owl-nav .owl-prev {
  opacity: 0;
  margin-left: 4rem;
}
.owl-nav-fade .owl-nav .owl-next {
  opacity: 0;
  margin-right: 4rem;
}
.owl-nav-fade:hover .owl-nav .owl-prev {
  opacity: 1;
  margin-left: 0;
}
.owl-nav-fade:hover .owl-nav .owl-next {
  opacity: 1;
  margin-right: 0;
}
.owl-split .owl-item:not(:last-child) {
  position: relative;
}
.owl-split .owl-item:not(:last-child):after {
  content: "";
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #ebebeb;
}
.owl-middle .owl-stage {
  display: flex;
  align-items: center;
}
.rotate-slider {
  padding-left: 17.9%;
}
.rotate-slider .owl-stage-outer {
  margin: -20px;
  padding: 20px;
  padding-left: 23%;
  margin-left: -23%;
}
.rotate-slider .owl-item {
  transition: 1s;
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.1);
}
.rotate-slider .owl-item:not(.active) {
  transform: scale(0.8) translateX(100%);
  transform-origin: left;
}
.rotate-slider .owl-nav .owl-next {
  right: auto;
  left: 40px;
  font-size: 36px;
}
.rotate-slider .owl-nav .owl-next:before {
  content: "";
}
.rotate-slider .owl-nav .owl-prev {
  display: none;
}
.address-title{
	background-color: #ebebeb;
    padding:10px 5px;
    border-radius: 6px;
	height: 40px;
}
.input-group-prepend{
    display: flex;
    align-items: center;
    padding: 0 10px;
    border: 1px #ebebeb solid;
    background: #efefef;
}
@media (max-width:800px) {
	.cart-product-img{
		display: none;
	  }
	  .cart-qty-desktop{
		display: none;
	  }
}
@media (min-width:800px) {
.cart-qty-mobile{
	display: none !important;
}

}
@media (max-width: 479px) {
  .rotate-slider .owl-nav .owl-next {
    right: auto;
    left: 15px;
    font-size: 24px;
  }
  .product-thumbnail{
	display: none;
  }
  .address-title, .address-title h3{
	font-size: 14px !important;
  }
  .cart .product-name{
	font-size: 15px;
  }
  .coupon-view{
	font-size: 15px;
  }
}

