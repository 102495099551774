.coupon .coupon-valid {
    border-left: 5px dashed #ffffff;
    width: 40% !important;
    position: relative;
    background:var(--rm-bg-3);
}
.coupon{
    background: var(--rm-bg);
    color: #ffffff;
    height: 125px;
}

.coupon .coupon-valid .info::after, .coupon .coupon-valid .info::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: #ffffff;
    border-radius: 100%;
}
.coupon .coupon-valid .info::before {
    top: -10px;
    left: -13px;
}

.coupon .coupon-valid .info::after {
    bottom: -10px;
    left: -13px;
}
.coupon .time {
    font-size: 1.6rem;
}
.coupon h3{
    margin: 0;
    color: #ffffff;
    padding: 0;
    font-size: 14px;
}
.valid{
    font-size: 16px;
}
.coupon-amount{
    margin: 10px 0 0 0;
    color: #000000;
}
.couponimg{
    width: 100px;
}
.text-muted{
    height: 57px;
    overflow: hidden;
}
@media (max-width: 600px) {
    .couponimg{
        width: 80px;
    }
    .coupon{
        font-size: 15px;
    }
}