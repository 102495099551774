.status-bar{

     padding: 5px 10px;
     margin: 0 0 10px 0;
     border-top: solid 1px #000;
    border-bottom: solid 1px #000;
}
.order-summery{
    font-size: 16px;
    color: #000000;
}
.order-summery h2{
    font-size: 18px;
    margin: 0;
    padding: 0;
}
.order-summery p{
    margin: 0;
    padding: 0;
}
.order-cancel{
    float: right;
}
.ordered-product{
    background-color: #eeeeee;
    padding: 10px;
    margin:10px 0 0  0;
    border-top: solid 1px #000;
    border-bottom: solid 1px #000;
}
.soldby{
    color: #767676;
}
.order-footer{
    border-bottom: #000 solid 1px;
    padding: 5px 0;
}
.ordered-product img {
    width: 100px;
}
@media (max-width: 800px) {
    .status-bar{
        font-size: 14px;
    }
    .order-summery h2{
        font-size: 16px;
    }
    .ordered-product img {
        width:55px;
    }
    .view-order-img{
        display: none;
    }

}

@media (max-width: 450px) {
    .ordered-product img {
        width: 50px;
    }
}