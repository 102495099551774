.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  width: 80%;
  /* Adjust the width as needed */
  max-width: 600px;
  /* Adjust the max-width as needed */
}

.product-wrap {
  border: 1px solid #f5f2e5;
  border-radius: 10px;
  margin-top: 15px;
  -webkit-transition: border .6s ease-out;
  -moz-transition: border .6s ease-out;
  -o-transition: border .6s ease-out;
  transition: border .6s ease-out;
  overflow: hidden;
  -webkit-transition: box-shadow .6s ease-out;
  -moz-transition: box-shadow .6s ease-out;
  -o-transition: box-shadow .6s ease-out;
  transition: box-shadow .6s ease-out;

}

.product-wrap:hover {
  border: 1px solid var(--rm-bg);
 
}

.rating-main {
  border-bottom: 8px #ffffff solid;
  margin: 0 -5px;
  padding: 0 5px;
}

.product-wrap:hover .rating-main {
 
}

.product {
  position: relative;
  overflow: hidden;
  font-family: poppins;
  font-size: 12px;
  color: #999;
  transition: 0.3s;
}

.product:hover .product-action,
.product:hover .product-action-vertical {
  visibility: visible;
  opacity: 1;
}

.product-media:hover img:first-child {
  opacity: 0;
}

.product-media:hover img:last-child {
  opacity: 1;
}

.product-media {
  position: relative;
  margin-bottom: 0;
  transition: box-shadow 0.3s;
  height: 300px;
}

.product-media img {
  display: block;
  width: 100%;
  height: auto;
  transition: all 0.5s;
}

.product-media img:last-child {
  position: absolute;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  left: 0;
  object-fit: cover;
}

.product-media img:first-child {
  position: relative;
  opacity: 1;
}

.product-label-group {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 2rem;
  top: 2rem;
  max-width: 10rem;
}

.product-test-wishlist {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 5px;
  top: 5px;

}

.product-label {
  display: inline-block;
  text-align: center;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1.1rem;
  font-size: 1.1rem;
  border-radius: 2px;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-transform: uppercase;
}

.product-label.label-new {
  background: #26c;
}

.product-label.label-sale {
  background: #d26e4b;
}

.product-label.label-top {
  background: #26c;
}

.product-label.label-stock {
  background: #999;
}

.btn-product-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  background-color: #fff;
  color: #999;
  font-size: 11px;
  font-weight: 700;
  transition: border-color 0.3s, color 0.3s, background-color 0.3s;
  width: 20px;
  height: 20px;
}

.btn-product-icon:hover {
  border-color: var(--rm-bg);
  color: #26c;

}

.btn-product-icon:not(.btn-wishlist) i {
  margin-bottom: 2px;
}

.btn-product-icon.btn-quickview i {
  font-size: inherit;
}

.btn-product-icon.btn-compare i {
  font-size: 1.8rem;
}

.btn-product {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 15PX;
  background-color: var(--rm-bg);
  color: #fff;
  font-family: poppins;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.025em;
  text-transform: uppercase;
  transition: opacity 0.3s;
  border-radius: 12px;

}

.btn-product:hover {
  color: #fff;
}

.img-horizontal,
.img-vertical {
  overflow: hidden;
}

#slideshow-items-container .img-horizontal {
  width: 100%;
  height: auto;
}

#slideshow-items-container .img-vertical {
  height: 100%;
  width: auto;
}

.product-image {
  height: 450px;
  text-align: center;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  margin: 0;
}

.product-image div {
  width: 100% !important;
  height: 100% !important;
}

.img-vertical .product-image img {
  height: 100% !important;
  width: auto !important;
  text-align: center;
  margin: auto;
}

.img-horizandal .product-image img {
  height: auto !important;
  width: 100% !important;
  text-align: center;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.product-form {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  line-height: 3rem;
  margin: 0 0 1rem;
}

.product-form>label {
  min-width: 7rem;
  margin-bottom: 1rem;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.025em;
}

.product-form.product-variations {
  line-height: 37px;
}

.product-form .select-box:before {
  right: 1.4rem;
  font-size: 1.2rem;
  color: #222;
}

.product-form select {
  max-width: none;
  padding: 0.8rem 3rem 0.8rem 1.4rem;
  color: #222;
  border-color: #ebebeb;
  font-size: 1.3rem;
}

.product-form .product-variations {
  display: block;
  margin-top: -3px;
  margin-bottom: -3px;
}

.product-form.product-qty {
  line-height: 4.5rem;
}

.product-form .quantity-minus {
  border-radius: 0.3rem 0 0 0.3rem;
}

.product-form .quantity-plus {
  border-radius: 0 0.3rem 0.3rem 0;
}

.product-form .input-group {
  margin-right: 1rem;
}

.product-form .btn-cart,
.product-form .btn-external {
  border: 0;
  flex: 1;
  min-width: 13rem;
  font-size: 1.4rem;
  border-radius: 0.3rem;
  background-color: var(--rm-bg);
  transition: background-color 0.3s;
  color: #fff;
  cursor: pointer;
  max-width: 20.7rem;
  height: 4.5rem;
}

.product-form .btn-cart:disabled,
.product-form .btn-external:disabled {
  background-color: #e4eaec;
  cursor: not-allowed;
  color: #999;
}

.product-form .btn-cart i,
.product-form .btn-external i {
  margin-right: 0.8rem;
  margin-top: -1px;
  font-size: 1.8rem;
  line-height: 0;
  vertical-align: middle;
}

.product-form .btn-cart i:before,
.product-form .btn-external i:before {
  margin: 0;
}

.product-form .btn-cart:hover:not(:disabled),
.product-form .btn-external:hover:not(:disabled) {
  background-color: var(--rm-bglite);
}

.product-external .product-short-desc {
  color: #ee8204;
}

.product-external .product-form button {
  background: #ee8204;
}

.product-form-group {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1 1;
  justify-content: center;
}
.seller-name{
  font-size: 17px;
}
.follower{
  font-size: 16px;
  font-weight: bold;
}
.seller-info p{
  margin: 0;
}
 
.product-form-group .product-variations {
  margin-bottom: 7px;
}

.product-form-group tr {
  border-bottom: 1px solid #eee;
}

.product-form-group td {
  padding: 2rem 0;
}

.product-form-group .item-name {
  padding-left: 2rem;
  color: #222;
  line-height: 1.4;
}

.product-form-group .item-price {
  font-weight: 600;
  color: #222;
  padding-left: 4.5rem;
}

.product-thumb {
  position: relative;
  margin: 0 0.5rem;
  cursor: pointer;
  overflow: hidden;
  width: 60px;
  height: 60px;
}

.product-single-carousel,
.product-thumbs-wrap {
  flex-basis: 100%;
  max-width: 100%;
}

.product-single-carousel .owl-nav .owl-next,
.product-single-carousel .owl-nav .owl-prev {
  color: #222;
  border: 0;
}

.product-image {
  position: relative;
}

.product-thumbs-wrap {
  position: relative;
  margin-top: 1rem;
  overflow: hidden;
}

.product-thumbs-wrap img {
  display: block;
}

.product-thumbs-wrap button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  border: 0;
  transition: opacity 0.3s, transform 0.3s;
  z-index: 1;
  opacity: 0;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  -webkit-appearance: none;
  cursor: pointer;
}

.product-thumbs-wrap:hover button:not(.disabled) {
  opacity: 0.9;
  transform: none;
}

.product-thumbs-wrap button:not(.disabled):hover {
  opacity: 1;
}

.product-thumbs-wrap>button {
  display: none;
}

.product-thumbs-wrap button,
.product-thumbs .owl-nav .owl-next,
.product-thumbs .owl-nav .owl-prev {
  background-color: #fff;
  color: #666;
  font-size: 1.6rem;
}

.product-thumb:before,
.product-thumbs-wrap button {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.product-thumbs {
  transition: top 0.3s ease-out;
  display: flex;
}

.product-thumbs.owl-carousel {
  margin: 0 -0.5rem;
  width: calc(100% + 1rem);
}

.product-thumbs .owl-stage {
  display: flex;
}

.product-thumbs .owl-prev {
  transform: translateX(-100%);
  left: 0.5rem;
}

.product-thumbs .owl-next {
  left: auto;
  right: 0.5rem;
  transform: translateX(100%);
}

.product-thumb:before {
  content: "";
  transition: border-color 0.3s;
  border: 2px solid transparent;
}

.product-thumb.active:before {
  border-color: #26c;
}

.product-thumb .btn-play {
  position: relative;
  display: block;
  z-index: 2;
}

.product-thumb .btn-play:before {
  content: "";
  position: absolute;
  left: -100px;
  top: -100px;
  right: -100px;
  bottom: -100px;
  background: rgba(0, 0, 0, 0.1);
  transition: background 0.3s;
}

.product-thumb .btn-play:after {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-size: 16px;
  font-weight: 800;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 36px;
  text-align: center;
  transition: box-shadow 0.3s;
}

.product-thumb .btn-play:hover:before {
  background: rgba(0, 0, 0, 0.2);
}

.product-thumb .btn-play:hover:after {
  box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.2);
}

.product-thumb .btn-video:after {
  content: "";
}

.product-single .product-cat {
  margin-bottom: 1.3rem;
  color: #999;
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: -0.025em;
}

.product-single .product-cat span {
  margin-right: 0.7rem;
}

.product-single .product-name h1 {
  margin-bottom: 0.6rem;
  font-size: 20px;
  font-weight: 600;
  white-space: normal;
  color: #222;
}

.product-single .product-price {
  display: block;
  font-weight: 700;
  letter-spacing: -0.025em;
  line-height: 1;
}

.product-single .old-price {
  font-size: 2.4rem;
  font-weight: 600;
}

.product-single .rating-reviews {
  font-size: 1.2rem;
}

.product-single .rating-reviews:not(:hover) {
  color: #999;
}

.product-wrap .ratings-container {
  margin: 0 0 0 -10px;
}
.ratings-container {
  display: flex;
  align-items: center;
  line-height: 1.1;
  font-size: 11px;
  letter-spacing: 0.2em;
  margin: 0;
  padding: 0;
}
.sold-out-label {
  margin: 0 -10px 0 0;

}

.product-single .ratings-container {
  font-size: 14px;
}

.product-single .ratings-container .ratings:before {
  color: #666;
}

.product-single .ratings-full {
  margin-top: -2px;
}

.product-single label {
  color: #222;
  font-weight: 600;
  text-transform: uppercase;
}

.product-single .product-action {
  display: inline-block;
}

.product-single .product-action .btn-wishlist {
  position: relative;
}

.product-single .divider {
  margin-left: -1rem;
  margin-right: 1.8rem;
}

.product-single .social-links {
  color: #999;
}

.product-single .social-link {
  border: none;
}

.product-single .social-link.social-facebook:hover {
  background-color: transparent;
  color: #3b5998;
}

.product-single .social-link.social-twitter:hover {
  background-color: transparent;
  color: #1da1f2;
}

.product-single .social-link.social-pinterest:hover {
  background-color: transparent;
  color: #bd081c;
}

.product-single .product-footer>* {
  margin-bottom: 1rem;
}

.product-single .btn-compare,
.product-single .btn-wishlist {
  display: inline-block;
  padding: 0.5rem 0;
  font-weight: 400;
  font-size: 1.4rem;
  background-color: transparent;
  transition: color 0.3s;
  text-transform: none;
  color: #666;
}

.product-single .btn-compare i,
.product-single .btn-wishlist i {
  display: inline-block;
  margin: 0 0.5rem 0.3rem 0;
  vertical-align: middle;
  font-size: 1.8rem;
  line-height: 0;
}

.product-single .btn-compare:hover,
.product-single .btn-wishlist:hover {
  color: #26c;
}

.product-single .btn-compare i {
  margin-right: 0.8rem;
  font-size: 2.1rem;
}

.product-single .quantity {
  font-weight: 700;
}

.product-single .product-meta+.product-countdown-container {
  margin-top: 0.4rem;
}

.product-single .product-countdown-container {
  display: inline-flex;
  align-items: center;
  margin-bottom: 2.5rem;
  padding: 1rem 1.6rem;
  border: 1px solid #444;
  background-color: #444;
  text-transform: none;
  line-height: 1;
  color: #fff;
  border-radius: 0.3rem;
}

.product-single .product-countdown-container label {
  margin-right: 0.5rem;
  text-transform: none;
  color: #fff;
}

.product-single .product-countdown-container .product-sale-info {
  position: relative;
  padding-right: 1.5rem;
}

.product-single .product-countdown-container .product-sale-info i {
  margin-right: 0.8rem;
}

.product-single .product-countdown-container .product-sale-info:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 2.4rem;
  background: rgba(255, 255, 255, 0.2);
}

.product-single .product-countdown-container .product-sale-info+label {
  margin-left: 1.5rem;
}

.product-single .product-label-group {
  top: 2rem;
  left: 2.5rem;
  z-index: 2;
  align-items: flex-start;
}

.product-single .product-label {
  color: #fff;
}

.product-single .product-variations>a:not(.size-guide) {
  width: 3rem;
  height: 3rem;
}

.product-single .color {
  border: 0;
}

.product-single .size {
  width: 3.6rem;
}

.product-single .accordion ul {
  list-style: none;
  padding-left: 0;
}

.product-single .rating-form label,
.product-single form label {
  font-weight: 400;
  font-size: 1.3rem;
  text-transform: none;
  color: #666;
}

.product-single {
  color: inherit;
  margin: 0px 0 5px 0;
}

.product-single .product-details {
  padding: 0 0 10px;
  background-color: transparent;
  font-size: 16px;
}

.container-fluid .product-single .product-details {
  padding-top: 4px;
}

aside+div .product-single .product-details {
  padding-top: 2px;
}

@media (max-width: 750px) {
  .facility {
    padding: 0;
  }

  .product-media {
    position: relative;
    margin-bottom: 0;
    transition: box-shadow 0.3s;
    height: 160px;
  }

  .product-divider {
    display: none;
  }
}

@media (max-width: 750px) {
  .product-image {
    height: auto;
    margin-bottom: 10px;
  }
 
}

@media (max-width: 450px) {
  .product-image {
    height: 450px;
  }
}

@media (max-width: 400px) {
  .product-image {
    height: 400px;
  }
}

@media (max-width: 350px) {
  .product-image {
    height: 350px;
  }
}

@media (max-width: 300px) {
  .product-image {
    height: 300px;
  }
}

.facility-product-page {
  border-left: solid #ebebeb 1px;
}

.facility-product-page li {
  font-size: 18px;
}

.facility-product-page .  {
  box-shadow: none;
}

.facility-product-page .facility-in {
  display: inline-flex;
}

.facility-product-page .facility {
  display: block;
}

 

.product-wrap .save-price, .new-price, .mrp-price {
   padding: 4px 5px;
  color: #26262c;
 
}
.mrp-price{
  text-decoration-line: line-through;
}

.product-wrap .save-price, .new-price, .mrp-price span {
  display: block;
  font-weight: 700;
}
.save-price{
  color:#00AA46!important;
}

.product-single .sold-out-label {
 padding: 0;
 margin: 0;
}

.product-wrap .sold-out-label {
  text-align: right;
}

.seller-companyname span {
  background-color: #f4f4f4;
  padding: 10px 15px;
  border-radius: 11px;
  color: #000;
}

.seller-companyname {
  display: block;
  margin-top: 15px;
}

/* varient style start */
.varient-color,
.varient-color-selected {
  border-radius: 50%;
  width: 65px;
  height: 65px;
  overflow: hidden;
  display: inline-flex;
  margin-right: 10px;
}

.varient-color-selected {
  box-shadow:0px 4px 0px 4px var(--rm-bg);
}

.varient-color span {
  width: 65px;
  height: 65px;
  overflow: hidden;
  display: block;
}

.varient-size,
.varient-size-selected {
  overflow: hidden;
  background: #cccccc;
  padding: 5px 15px;
  color: #000000;
  display: inline-flex;
  margin-right: 10px;
}

.varient-size-selected {
  border: var(--rm-bg) solid 5px;
}

.varient-size-disabled {
  opacity: 0.3;
}

/* varient style end */
/* seller bar start */
.seller-profile-bar {
  background-color: var(--rm-bg);
  border-radius: 30px;
  color: #ffffff;
}

.seller-img {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  margin: auto;
}

.seller-img img {
  width: 100%;
}

.seller-view-page-bg {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.seller-view-page {
  display: inline-block;
 
  padding: 10px 15px;
 
  cursor: pointer;
  color: #000;
}

.product-action-vertical {
  position: absolute;
  top: 15px;
  right: 15px;
  transition: opacity 0.3s, visibility 0.3s;
  opacity: 0;
  visibility: hidden;
}
.product-action-vertical .btn-wishlist.loading {
  overflow: hidden;
}
.product-action {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background-color: transparent;
  z-index: 10;
  transition: opacity 0.3s, visibility 0.3s;
  opacity: 0;
}
.product-details {
  position: relative;
  background-color: #fff;
  padding-top: 2px;
  padding: 2px 5px 0 5px;
}
.product-details h3{
font-size: 16px;
font-weight: normal;
height: 37px;
overflow: hidden;
line-height: 18px;
}
.product-details > .btn-wishlist {
  position: absolute;
  top: 1.2rem;
  right: 0;
  color: #999;
  z-index: 1;
  font-size: 1.6rem;
}
.product-details > .btn-wishlist i {
  display: inline-block;
  margin: 0;
  transition: transform 0.3s;
}
.product-details > .btn-wishlist:hover {
  color: #26c;
}
.product-details > .btn-wishlist:hover i {
  transform: rotateY(180deg);
}
.product-details > :last-child {
  margin-bottom: 0;
}
.product-details .product-action {
  position: relative;
  opacity: 1;
  visibility: visible;
}
.product-cat {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.product-cat a:hover {
  color: #26c;
}
.product-name h1 {
  margin-bottom: 0.3rem;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: -0.01em;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product-name h1 a:hover {
  color: #26c;
}
.product-price {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  align-items: center;
  flex-wrap: wrap;
  font-size: 15px;
  font-weight: 600;
  color: #222;
  padding: 0 2px;
  display: flex;
}
.product-price div{
  margin: 0 15px 0 0;
  float: left;
}
.product-price .old-price {
  color: #aaa;
}
.product-price .new-price {
  text-decoration: none;

}
.product-single  .product-price{
  font-size:22px;

}

.seller-profile-bar p {
  margin: 13px 0 0 0;
  padding: 0;

}

.seller-profile .seller-profile-bar {
  border-radius: 0 0 30px 30px;
}

.seller-profile .seller-view-page {
  border-radius: 0 0 30px 0;
}

.seller-catagory {
  display: flex;
  justify-content: center;
  padding: 12px 0;
  margin: 0 0 15px 0;
  border-bottom: 1px solid #cccccc;
}

.seller-catagory a {
  margin: 0 15px;
  font-size: 15px;
}

/* seller bar end */
.zoom-modal-content .ant-modal-content {
  background-color: transparent;
  box-shadow: none;
}

.ant-modal-close {
  background-color: #fff !important;
  border-radius: 50px !important;
}

.variants img {
  max-width: 120%;
}

.review-popup {
  background-color: #fff;
  padding: 10px;
}
.price-box{
  display: flex;
}
.color-varient, .color-size{
 
}
.color-varient  div {
  padding: 0;
}

.cart-btn{
  background-color:var(--rm-bg);
  font-size: 18px;
  color: var(--rm-font) ;
  width:100%;
  text-align: center;
  padding: 10px 0;
  border: none;
  cursor: pointer;  
  border: 2px solid var(--rm-bg);
  border-radius: 30px;
  margin: 0 20px;
}
.checkout-cart-btn{
  background-color:var(--rm-bg);
  font-size: 18px;
  border-radius: 10px;
  color: var(--rm-font) ;
  width:100%;
  text-align: center;
  margin: 0 5px;
  border: none;
  cursor: pointer;  
}
.buyer-cart-btn{
border: solid 2px var(--rm-bg);
background-color:#ffffff;
color: #000;
border-radius: 30px;
}
.cart-added{
  border: none;
  background-color: transparent;
  color: #000;
}
.seller-store{
  padding: 20px 0 20px 0;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.seller-profile{
  padding:0 0 0 0;
}
.seller-bg {
  display: block;
  margin: 0;
  padding: 0;
}
.seller-bg img{
  width: 100%;
}
.follow{
  line-height: 36px;
  padding: 0 10px;
  background-color: var(--rm-bg);
  font-size: 12px;
  color: #ffffff;
  display: flex;
}
.seller-info-set p{
  
      font-size: 17px;
    color: #000;
}
@media (max-width: 992px) {
  .seller-name{
    font-size: 14px;
  }
  .product-price{
    font-weight: normal;
    font-size: 16px;
  }
  .seller-store{
    border-bottom: #cccccc solid 1px;
  }
  .color-varient{
     
  }
  .color-size{
    
  }
  .rate-qty{
    padding: 10px 0;
    border-bottom: #cccccc solid 1px;
  }
  
  .product-single .sold-out-label{
    display: flex;
    justify-content: flex-end;
  }
  .sold-review{
    flex-direction: row-reverse;
    padding: 10px 0;
    border-bottom: #cccccc solid 1px;
  }
  .price-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: #cccccc solid 1px;
  }
  .product-details h3{
    font-size: 12px;
  }
  .seller-view-page-bg {
    width: 100%;
    margin: 15px 0 0 0;
  }

  .seller-profile {
    width: 100%;
  }

  .seller-data {
    width: 50%;
    margin-top: -15px;
  }

  .seller-view-page {
    padding: 0;
    text-align: center;
    font-size: 48px;
  }

  .seller-profile-input {
    width: 100%;
  }
  .product-form-group {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #ffffff;
    z-index: 999;
    left: 0;
    
  }
  .facility-product-page{
    display:  none;
  }
  .product-single .product-name h1{
    font-size: 19px;
    margin: 5px 0;
  }
  .product-single .product-name{
    padding:15px 0;
    border-bottom: #cccccc solid 1px;
  }
  
  .breadcrumb{
    font-size: 12px;
    padding:10px 0;
    border-bottom: #cccccc solid 1px;
  }
  .product-single .product-price{
    font-size: 18px;
  }
  #slideshow-items-container{
    height: 350px;
  }
  #slideshow-items-container .img-horizontal{
    width:auto;
    height:  100%;
  }
  #slideshow-items-container .img-vertical{

  }
  #product-tab-description{
    font-size: 12px;
    padding: 0;
    margin: 0;
  }
}

@media (max-width: 768px){
  .seller-img {
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 50%;
    margin:0 auto;
  }
  .seller-info-set p{
    display:block;
  }
   
  
}
@media (min-width: 768px){
  .product-single .sold-out-label{
    display: flex;
    justify-content: flex-start;
  }  
}
@media (max-width: 900px){
  .seller-img {
    width: 50px;
    height: 50px;
    margin: 0 auto;

  }
   
  .product-single .product-name h1{
    font-size: 17px;
  }
  .product-single .product-details{
    font-size: 14px;
  }
  .seller-info-set p{
    font-size: 15px;
    margin: 0;
    padding: 0;
  }
  .seller-view-page-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.seller-view-page{
  font-size: 16px;
}

}

.variant-img:hover {
  /* Add your hover styles here */
  opacity: 0.7; /* For example, reducing opacity on hover */
}
